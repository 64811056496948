export const FETCH_USER_DETAILS_REQUEST = 'FETCH_USER_DETAILS_REQUEST';
export const FETCH_USER_DETAILS_SUCCESS = 'FETCH_USER_DETAILS_SUCCESS';
export const FETCH_USER_DETAILS_FAILURE = 'FETCH_USER_DETAILS_FAILURE';
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const FETCH_CUSTOMER_PROFILE_REQUEST = 'FETCH_CUSTOMER_PROFILE_REQUEST';
export const FETCH_CUSTOMER_PROFILE_SUCCESS = 'FETCH_CUSTOMER_PROFILE_SUCCESS';
export const FETCH_CUSTOMER_PROFILE_FAILURE = 'FETCH_CUSTOMER_PROFILE_FAILURE';
export const RESET_FETCH_CUSTOMER_PROFILE = 'RESET_FETCH_CUSTOMER_PROFILE';

export const FETCH_CUSTOMER_ONBOARDING_REQUEST =
  'FETCH_CUSTOMER_ONBOARDING_REQUEST';
export const FETCH_CUSTOMER_ONBOARDING_SUCCESS =
  'FETCH_CUSTOMER_ONBOARDING_SUCCESS';
export const FETCH_CUSTOMER_ONBOARDING_FAILURE =
  'FETCH_CUSTOMER_ONBOARDING_FAILURE';
export const RESET_FETCH_CUSTOMER_ONBOARDING =
  'RESET_FETCH_CUSTOMER_ONBOARDING';

export const FETCH_CUSTOMER_GFNACCOUNT_REQUEST =
  'FETCH_CUSTOMER_GFNACCOUNT_REQUEST';
export const FETCH_CUSTOMER_GFNACCOUNT_SUCCESS =
  'FETCH_CUSTOMER_GFNACCOUNT_SUCCESS';
export const FETCH_CUSTOMER_GFNACCOUNT_FAILURE =
  'FETCH_CUSTOMER_GFNACCOUNT_FAILURE';
export const RESET_FETCH_CUSTOMER_GFNACCOUNT =
  'RESET_FETCH_CUSTOMER_GFNACCOUNT';

export const FETCH_CUSTOMER_GFN_H3_ACCOUNT_REQUEST =
  'FETCH_CUSTOMER_GFN_H3_ACCOUNT_REQUEST';
export const FETCH_CUSTOMER_GFN_H3_ACCOUNT_SUCCESS =
  'FETCH_CUSTOMER_GFN_H3_ACCOUNT_SUCCESS';
export const FETCH_CUSTOMER_GFN_H3_ACCOUNT_FAILURE =
  'FETCH_CUSTOMER_GFN_H3_ACCOUNT_FAILURE';
export const RESET_FETCH_CUSTOMER_GFN_H3_ACCOUNT =
  'RESET_FETCH_CUSTOMER_GFN_H3_ACCOUNT';

export const FETCH_CUSTOMER_IPAAS_REQUEST = 'FETCH_CUSTOMER_IPAAS_REQUEST';
export const FETCH_CUSTOMER_IPAAS_SUCCESS = 'FETCH_CUSTOMER_IPAAS_SUCCESS';
export const FETCH_CUSTOMER_IPAAS_FAILURE = 'FETCH_CUSTOMER_IPAAS_FAILURE';
export const RESET_FETCH_CUSTOMER_IPAAS = 'RESET_FETCH_CUSTOMER_IPAAS';

export const FETCH_CUSTOMER_PAYCARDREG_REQUEST =
  'FETCH_CUSTOMER_PAYCARDREG_REQUEST';
export const FETCH_CUSTOMER_PAYCARDREG_SUCCESS =
  'FETCH_CUSTOMER_PAYCARDREG_SUCCESS';
export const FETCH_CUSTOMER_PAYCARDREG_FAILURE =
  'FETCH_CUSTOMER_PAYCARDREG_FAILURE';
export const RESET_FETCH_CUSTOMER_PAYCARDREG =
  'RESET_FETCH_CUSTOMER_PAYCARDREG';

export const FETCH_CUSTOMER_ADDRESS_REQUEST = 'FETCH_CUSTOMER_ADDRESS_REQUEST';
export const FETCH_CUSTOMER_ADDRESS_SUCCESS = 'FETCH_CUSTOMER_ADDRESS_SUCCESS';
export const FETCH_CUSTOMER_ADDRESS_FAILURE = 'FETCH_CUSTOMER_ADDRESS_FAILURE';
export const RESET_FETCH_CUSTOMER_ADDRESS = 'RESET_FETCH_CUSTOMER_ADDRESS';

export const FETCH_CUSTOMER_INVOICE_DETAILS_REQUEST =
  'FETCH_CUSTOMER_INVOICE_DETAILS_REQUEST';
export const FETCH_CUSTOMER_INVOICE_DETAILS_SUCCESS =
  'FETCH_CUSTOMER_INVOICE_DETAILS_SUCCESS';
export const FETCH_CUSTOMER_INVOICE_DETAILS_FAILURE =
  'FETCH_CUSTOMER_INVOICE_DETAILS_FAILURE';
export const RESET_FETCH_CUSTOMER_INVOICE_DETAILS =
  'RESET_FETCH_CUSTOMER_INVOICE_DETAILS';

export const FETCH_SOA_DETAILS_REQUEST = 'FETCH_SOA_DETAILS_REQUEST';
export const FETCH_SOA_DETAILS_SUCCESS = 'FETCH_SOA_DETAILS_SUCCESS';
export const FETCH_SOA_DETAILS_FAILURE = 'FETCH_SOA_DETAILS_FAILURE';
export const RESET_FETCH_SOA_DETAILS = 'RESET_FETCH_SOA_DETAILS';

export const UPDATE_CUSTOMER_VERIFY_REQUEST = 'UPDATE_CUSTOMER_VERIFY_REQUEST';
export const UPDATE_CUSTOMER_VERIFY_SUCCESS = 'UPDATE_CUSTOMER_VERIFY_SUCCESS';
export const UPDATE_CUSTOMER_VERIFY_FAILURE = 'UPDATE_CUSTOMER_VERIFY_FAILURE';
export const RESET_UPDATE_CUSTOMER_VERIFY = 'RESET_UPDATE_CUSTOMER_VERIFY';

export const FETCH_CUSTOMER_CARDS_REQUEST = 'FETCH_CUSTOMER_CARDS_REQUEST';
export const FETCH_CUSTOMER_CARDS_SUCCESS = 'FETCH_CUSTOMER_CARDS_SUCCESS';
export const FETCH_CUSTOMER_CARDS_FAILURE = 'FETCH_CUSTOMER_CARDS_FAILURE';
export const RESET_FETCH_CUSTOMER_CARDS = 'RESET_FETCH_CUSTOMER_CARDS';

export const FETCH_CUSTOMER_BUSINESS_REQUEST =
  'FETCH_CUSTOMER_BUSINESS_REQUEST';
export const FETCH_CUSTOMER_BUSINESS_SUCCESS =
  'FETCH_CUSTOMER_BUSINESS_SUCCESS';
export const FETCH_CUSTOMER_BUSINESS_FAILURE =
  'FETCH_CUSTOMER_BUSINESS_FAILURE';
export const RESET_FETCH_CUSTOMER_BUSINESS = 'RESET_FETCH_CUSTOMER_BUSINESS';

export const DELETE_CUSTOMER_ACCOUNT_REQUEST =
  'DELETE_CUSTOMER_ACCOUNT_REQUEST';
export const DELETE_CUSTOMER_ACCOUNT_SUCCESS =
  'DELETE_CUSTOMER_ACCOUNT_SUCCESS';
export const DELETE_CUSTOMER_ACCOUNT_FAILURE =
  'DELETE_CUSTOMER_ACCOUNT_FAILURE';
export const RESET_DELETE_CUSTOMER_ACCOUNT = 'RESET_DELETE_CUSTOMER_ACCOUNT';

export const CUSTOMER_ACCOUNT_CLOSER_REQUEST =
  'CUSTOMER_ACCOUNT_CLOSER_REQUEST';
export const CUSTOMER_ACCOUNT_CLOSER_SUCCESS =
  'CUSTOMER_ACCOUNT_CLOSER_SUCCESS';
export const CUSTOMER_ACCOUNT_CLOSER_FAILURE =
  'CUSTOMER_ACCOUNT_CLOSER_FAILURE';

  export const RESET_CUSTOMER_ACCOUNT_CLOSER =
  'RESET_CUSTOMER_ACCOUNT_CLOSER';

export const BLOCK_CUSTOMER_ACCOUNT_REQUEST = 'BLOCK_CUSTOMER_ACCOUNT_REQUEST';
export const BLOCK_CUSTOMER_ACCOUNT_SUCCESS = 'BLOCK_CUSTOMER_ACCOUNT_SUCCESS';
export const BLOCK_CUSTOMER_ACCOUNT_FAILURE = 'BLOCK_CUSTOMER_ACCOUNT_FAILURE';
export const RESET_BLOCK_CUSTOMER_ACCOUNT = 'RESET_BLOCK_CUSTOMER_ACCOUNT';

export const FETCH_CUSTOMER_H3INVOICE_REQUEST =
  'FETCH_CUSTOMER_H3INVOICE_REQUEST';
export const FETCH_CUSTOMER_H3INVOICE_SUCCESS =
  'FETCH_CUSTOMER_H3INVOICE_SUCCESS';
export const FETCH_CUSTOMER_H3INVOICE_FAILURE =
  'FETCH_CUSTOMER_H3INVOICE_FAILURE';
export const RESET_FETCH_CUSTOMER_H3INVOICE = 'RESET_FETCH_CUSTOMER_H3INVOICE';

export const FETCH_CUSTOMER_CHARGEBACK_REQUEST = 'FETCH_CUSTOMER_CHARGEBACK_REQUEST';
export const FETCH_CUSTOMER_CHARGEBACK_SUCCESS = 'FETCH_CUSTOMER_CHARGEBACK_SUCCESS';
export const FETCH_CUSTOMER_CHARGEBACK_FAILURE = 'FETCH_CUSTOMER_CHARGEBACK_FAILURE';

export const FETCH_SOA_CHARGEBACK_REQUEST = 'FETCH_SOA_CHARGEBACK_REQUEST';
export const FETCH_SOA_CHARGEBACK_SUCCESS = 'FETCH_SOA_CHARGEBACK_SUCCESS';
export const FETCH_SOA_CHARGEBACK_FAILURE = 'FETCH_SOA_CHARGEBACK_FAILURE';

export const FETCH_CUSTOMER_ONFIDO_REQUEST = 'FETCH_CUSTOMER_ONFIDO_REQUEST';
export const FETCH_CUSTOMER_ONFIDO_SUCCESS = 'FETCH_CUSTOMER_ONFIDO_SUCCESS';
export const FETCH_CUSTOMER_ONFIDO_FAILURE = 'FETCH_CUSTOMER_ONFIDO_FAILURE';
export const RESET_FETCH_CUSTOMER_ONFIDO = 'RESET_FETCH_CUSTOMER_ONFIDO';

export const FETCH_CUSTOMER_DETAILS = 'FETCH_CUSTOMER_DETAILS';
export const FETCH_CUSTOMER_DETAILS_SUCCESS = 'FETCH_CUSTOMER_DETAILS_SUCCESS';
export const FETCH_CUSTOMER_DETAILS_FAILURE = 'FETCH_CUSTOMER_DETAILS_FAILURE';
export const RESET_FETCH_CUSTOMER_DETAILS = 'RESET_FETCH_CUSTOMER_DETAILS';

export const UPDATE_USER_NAME_REQUEST = 'UPDATE_USER_NAME_REQUEST';
export const UPDATE_USER_NAME_SUCCESS = 'UPDATE_USER_NAME_SUCCESS';
export const UPDATE_USER_NAME_FAILURE = 'UPDATE_USER_NAME_FAILURE';
export const RESET_UPDATE_USER_NAME = 'RESET_UPDATE_USER_NAME';
