import {
  Button,
  Card,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import KeyboardArrowUpIcon from '@material-ui/icons/ArrowDropUp';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { forwardRef, useState } from 'react';
import { ResponsiveContainer } from 'recharts';
import { getIn, useFormik } from 'formik';
import { manageOfferSchema } from '../schemas/manageOfferSchema';
import { Alert } from '@material-ui/lab';
import { connect, useDispatch } from 'react-redux';
import {
  createCardTypeRequest,
  createFeeGroupRequest,
  createPriceProfileRequest,
  createTokenTypeRequest,
  fetchOfferingPackageRequest,
  resetCreateCardTypeRequest,
  resetCreateFeeGroupRequest,
  resetCreatePriceProfileRequest,
  resetCreateTokenTypeRequest,
} from '../store/offeringPackages/actions';
import Spinner from './Spinner';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiGrid-container': {
      border: '1px solid #c4c4c4',
    },
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
    '& .MuiInputBase-root, .MuiFormLabel-root': {
      fontFamily: 'Shell-Book',
      color: '#0000008a',
    },
    '& .MuiFormHelperText-root': {
      fontFamily: 'Shell-Book',
    },
    fontFamily: 'Shell-Book',
    padding: 10,
  },
  input: {
    margin: '0px',
  },
  productGroupInput: {
    '&  .MuiFormLabel-root': {
      fontWeight: 600,
    },
    '& .Mui-disabled': { background: '#f3f3f3' },
    '& .MuiFormLabel-root.Mui-disabled': { background: 'none' },
    '& .MuiInputBase-root': {
      flexFlow: 'wrap',
    },
    margin: '0px',
  },
  titleMsg: {
    /*  '&:hover': {
      background: '#fed700d1',
    },
    */
    fontFamily: 'Shell-Medium',
    padding: '10px',
    fontSize: '1rem',
    flex: '100%',
    background: '#fed70075',
  },
  dialogTitle: {
    '& .MuiTypography-h6': {
      fontFamily: 'Shell-Medium',
    },
    fontFamily: 'Shell-Medium',
  },
  divider: {
    margin: '20px 0px 10px 0px',
  },
  inputFormControl: {
    margin: theme.spacing(1),
    width: 325,
  },
  checkboxDiv: {
    display: 'flex',
    alignItems: 'baseline',
  },
  checkbox: {
    '& .MuiTypography-body1': { fontFamily: 'Shell-Book' },
    display: 'flex',
    alignItems: 'center',
    margin: '15px 0px 0px 28px',
    color: '#0000008a',
  },
  searchButton: {
    margin: theme.spacing(1, 2, 1, 2),
    color: '#000000cf',
    backgroundColor: '#fbcf04',
    height: 40,
    fontFamily: 'Shell-Book',
    '&:hover': { background: '#fbcf04d1' },
  },
  editButton: {
    margin: theme.spacing(1, 2, 1, 2),
    color: '#000000cf',
    height: 40,
    width: 80,
    fontFamily: 'Shell-Book',
    '&:hover': { background: '#d4d4d4' },
  },
  chips: {
    margin: 1,
  },
  dialogActions: {
    justifyContent: 'right',
  },
  offerDetailsDiv: {
    flex: '100%',
    marginBottom: 2,
    border: '1px solid #c4c4c4',
    cursor: 'pointer',
  },
  inputAdornment: {
    '& .MuiTypography-body1': { fontFamily: 'Shell-Book' },
  },
}));

const offerDetailsTextItems: {
  feeGroup: { key: string; label: string; cur?: boolean }[];
  priceProfile: { key: string; label: string; cur?: boolean }[];
  tokenType: { key: string; label: string }[];
} = {
  feeGroup: [
    { key: 'feeGroup.name', label: 'Name' },
    { key: 'feeGroup.id', label: 'ID' },
    { key: 'feeGroup.setupFee', label: 'Setup Fee', cur: true },
    { key: 'feeGroup.monthlyFee', label: 'Monthly Fee', cur: true },
    { key: 'feeGroup.cardMonthlyFee', label: 'Card Monthly Fee', cur: true },
    { key: 'feeGroup.transactionFee', label: 'Transaction Fee', cur: true },
    { key: 'feeGroup.annualFee', label: 'Annual Fee', cur: true },
    {
      key: 'feeGroup.cardReplacementFee',
      label: 'Card Replacement Fee',
      cur: true,
    },
    { key: 'feeGroup.oneOffFee', label: 'One Off Fee', cur: true },
    { key: 'feeGroup.ipaasCode', label: 'IPaas Code' },
  ],
  priceProfile: [
    { key: 'priceProfile.name', label: 'Name' },
    { key: 'priceProfile.id', label: 'ID' },
    { key: 'priceProfile.vPowerDiscount', label: 'V Power Discount' },
    { key: 'priceProfile.mainGrade', label: 'Main Grade' },
    { key: 'priceProfile.dieselAGO', label: 'Diesel AGO' },
    { key: 'priceProfile.petrolMOGAS', label: 'Petrol MOGAS' },
    { key: 'priceProfile.lubricants', label: 'Lubricants' },
    { key: 'priceProfile.carWash', label: 'Car Wash' },
    { key: 'priceProfile.mainGradeTPN', label: 'Main Grade TPN' },
    { key: 'priceProfile.dieselAGOTPN', label: 'Diesel AGOTPN' },
    { key: 'priceProfile.fuelSaveDiesel', label: 'Fuel Save Diesel' },
    { key: 'priceProfile.fuelSaveMOGAS', label: 'Fuel Save MOGAS' },
    { key: 'priceProfile.vPowerMOGAS', label: 'V Power MOGAS' },
    { key: 'priceProfile.lpg', label: 'LPG' },
    { key: 'priceProfile.evShellNetwork', label: 'EV Shell Network' },
    { key: 'priceProfile.evOnRoam', label: 'EV on Roam' },
  ],
  tokenType: [
    { key: 'tokenType.name', label: 'Name' },
    { key: 'tokenType.id', label: 'Token Type ID' },
  ],
};

const initialOfferValues: any = {
  category: '',
  subCategory: '',
  customerGroupType: { name: '', companyRequired: false },
  feeGroup: {
    countryCode: '',
    marketId: '',
    name: '',
    id: '',
    setupFee: 0.0,
    monthlyFee: 0.0,
    cardMonthlyFee: 0.0,
    transactionFee: 0.0,
    annualFee: 0.0,
    cardReplacementFee: 0.0,
    oneOffFee: 0.0,
    ipaasCode: 0.0,
  },
  priceProfile: {
    countryCode: '',
    name: '',
    id: '',
    vPowerDiscount: 0.0,
    mainGrade: 0.0,
    dieselAGO: 0.0,
    petrolMOGAS: 0.0,
    lubricants: 0.0,
    carWash: 0.0,
    mainGradeTPN: 0.0,
    dieselAGOTPN: 0.0,
    fuelSaveDiesel: 0.0,
    fuelSaveMOGAS: 0.0,
    vPowerMOGAS: 0.0,
    lpg: 0.0,
    evShellNetwork: 0.0,
    evOnRoam: 0.0,
  },
  tokenType: { countryCode: '', name: '', id: '' },
  cardType: { countryCode: '', name: '', id: '', isHybrid: 'false' },
};

const ManageOffers = forwardRef((props: any, ref: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    setModalOpen,
    offeringPackage,
    offerType,
    feeGroupCreated,
    priceProfileCreated,
    tokenTypeCreated,
    cardTypeCreated,
  } = props;
  const [open, setDialogOpen] = useState(true);
  const countryCode = offeringPackage.country.toUpperCase();
  const currencyId = offeringPackage.currencyId;
  const [collOpen, setCollOpen] = useState('');
  const [isCreateSuccessful, setCreateSuccessful] = useState(false);
  const formik = useFormik({
    initialValues: initialOfferValues,
    validationSchema: manageOfferSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      formatValues(values, offeringPackage.marketId, countryCode, dispatch);
      setSubmitting(false);
      resetForm();
    },
  });

  function DisplayAlert() {
    if (Object.keys(formik.errors).length > 0) {
      return (
        <Alert
          style={{
            float: 'left',
            margin: '0px 5px',
            fontFamily: 'Shell-Book',
          }}
          severity="error"
        >
          {Object.keys(formik.errors).map((err: any) => {
            return err === 'feeGroup'
              ? 'Please enter Fee Group Details or Collapse to select other option. '
              : err === 'priceProfile'
                ? 'Please enter Price Profile Details or Collapse to select other option. '
                : err === 'tokenType'
                  ? 'Please enter Token Type Details or Collapse to select other option. '
                  : err === 'cardType'
                    ? 'Please enter Card Type Details. '
                    : _.get(formik.errors, err) || '';
          })}
        </Alert>
      );
    } else if (_.get(formik.values, 'category') === 'cardDetails') {
      if (cardTypeCreated.response.success) {
        setCreateSuccessful(true);
        return <Alert severity="success">Card Type Created Successfully</Alert>;
      } else if (
        (Object.keys(cardTypeCreated.response).length > 0 &&
          !cardTypeCreated.response.success) ||
        cardTypeCreated.error
      ) {
        return (
          <Alert severity="error">
            {`Error creating Card Type - [${cardTypeCreated.response.message || cardTypeCreated.error
              }]`}
          </Alert>
        );
      }
    } else if (
      _.get(formik.values, 'category') === 'offerDetails' &&
      _.get(formik.values, 'subCategory') === 'feeGroup'
    ) {
      if (feeGroupCreated.response.success) {
        setCreateSuccessful(true);
        return <Alert severity="success">Fee Group Created Successfully</Alert>;
      } else if (
        (Object.keys(feeGroupCreated.response).length > 0 &&
          !feeGroupCreated.response.success) ||
        feeGroupCreated.error
      ) {
        return (
          <Alert severity="error">
            {`Error creating Fee Group - [${feeGroupCreated.response.message || feeGroupCreated.error
              }]`}
          </Alert>
        );
      }
    } else if (
      _.get(formik.values, 'category') === 'offerDetails' &&
      _.get(formik.values, 'subCategory') === 'priceProfile'
    ) {
      if (priceProfileCreated.response.success) {
        setCreateSuccessful(true);
        return (
          <Alert severity="success">Price Profile Created Successfully</Alert>
        );
      } else if (
        (Object.keys(priceProfileCreated.response).length > 0 &&
          !priceProfileCreated.response.success) ||
        priceProfileCreated.error
      ) {
        return (
          <Alert severity="error">
            {`Error creating Price Profile - [${priceProfileCreated.response.message || priceProfileCreated.error
              }]`}
          </Alert>
        );
      }
    } else if (
      _.get(formik.values, 'category') === 'offerDetails' &&
      _.get(formik.values, 'subCategory') === 'tokenType'
    ) {
      if (tokenTypeCreated.response.success) {
        setCreateSuccessful(true);
        return (
          <Alert severity="success">Token Type Created Successfully</Alert>
        );
      } else if (
        (Object.keys(tokenTypeCreated.response).length > 0 &&
          !tokenTypeCreated.response.success) ||
        tokenTypeCreated.error
      ) {
        return (
          <Alert severity="error">
            {`Error creating Token Type - [${tokenTypeCreated.response.message || tokenTypeCreated.error
              }]`}
          </Alert>
        );
      }
    }
    return <></>;
  }

  const handleCloseButton = () => {
    formik.values.subCategory = '';
    setDialogOpen(false);
    setModalOpen(false);
    dispatch(resetCreateFeeGroupRequest());
    dispatch(resetCreatePriceProfileRequest());
    dispatch(resetCreateTokenTypeRequest());
    dispatch(resetCreateCardTypeRequest());
    if (isCreateSuccessful) {
      dispatch(fetchOfferingPackageRequest(countryCode));
    }
  };

  return (
    <Dialog key={'MO-dialog'} ref={ref} open={open} fullWidth maxWidth="lg">
      <form style={{ flex: 'auto' }} onSubmit={formik.handleSubmit}>
        <DialogTitle key={'MO-dialogTitle'} className={classes.dialogTitle}>
          Manage
          {' ' +
            offerType.charAt(0).toUpperCase() +
            offerType.substring(1).replace(/([a-z0-9])([A-Z])/g, '$1 $2')}
        </DialogTitle>
        <DialogContent key={'MO-dialogContent'}>
          <ResponsiveContainer key="MO-responsiveContainer">
            <Card
              key="manageOffer-grid-card"
              className={classes.root}
              variant="elevation"
            >
              <Grid
                key="manageOffer-grid"
                container
                justifyContent="flex-start"
              >
                <TextField
                  type="hidden"
                  key={`MO-customerGroupTypeName-textfield`}
                  name="category"
                  variant="outlined"
                  size="small"
                  value={_.set(formik.values, 'category', offerType)}
                  style={{ display: 'none' }}
                />
                <FormDisplay
                  offerType={offerType}
                  countryCode={countryCode}
                  currencyId={currencyId}
                  classes={classes}
                  formik={formik}
                  collOpen={collOpen}
                  setCollOpen={setCollOpen}
                />
                <Divider key={'divider-1'} className={classes.divider} />
              </Grid>
            </Card>
          </ResponsiveContainer>
        </DialogContent>

        <DialogActions
          className={classes.dialogActions}
          key={'MO-dialogActions'}
        >
          <DisplayAlert />
          <div style={{ float: 'right' }}>
            {feeGroupCreated.pending ||
              priceProfileCreated.pending ||
              tokenTypeCreated.pending ||
              cardTypeCreated.pending ? (
              <div style={{ width: 200, height: 60 }}>
                {' '}
                <Spinner
                  inline
                  title={`Creating ${formik.values.subCategory || formik.values.category
                    }...`}
                />
              </div>
            ) : (
              <>
                <Button
                  key={'MO-edit-button'}
                  color="default"
                  onClick={handleCloseButton}
                  variant="contained"
                  className={classes.editButton}
                >
                  Close
                </Button>
                <Button
                  key={'MO-submit-button'}
                  type="submit"
                  onSubmit={() => formik.handleSubmit}
                  className={classes.searchButton}
                  variant="contained"
                >
                  Submit
                </Button>
              </>
            )}
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
});
const mapStateToProps = (state: any) => {
  return {
    countries: state.countries.countries,
    countriesError: state.countries.error,
    countriesLoading: state.countries.pending,
    deals: state.deals.deals,
    dealsLoading: state.deals.pending,
    dealsError: state.deals.error,
    feeGroupCreated: state.feeGroupCreated,
    priceProfileCreated: state.priceProfileCreated,
    tokenTypeCreated: state.tokenTypeCreated,
    cardTypeCreated: state.cardTypeCreated,
  };
};
export default connect(mapStateToProps, null, null, { forwardRef: true })(
  ManageOffers
);

function FormDisplay(props: any) {
  const {
    offerType,
    classes,
    formik,
    countryCode,
    currencyId,
    collOpen,
    setCollOpen,
  } = props;

  switch (offerType) {
    case 'aggregatorDetails':
      return (
        <>
          <div key="aggregatorDetails-grid-div" className={classes.titleMsg}>
            Customer Group Type
          </div>
          <div style={{ display: 'flex' }}>
            <FormControl
              variant="outlined"
              className={classes.inputFormControl}
              error={Boolean(
                _.get(formik.touched, 'customerGroupType.name') &&
                _.get(formik.errors, 'customerGroupType.name')
              )}
              size="small"
            >
              <TextField
                type="text"
                key={`MO-customerGroupTypeName-textfield`}
                name="customerGroupType.name"
                variant="outlined"
                size="small"
                value={_.get(formik.values, 'customerGroupType.name')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={classes.input}
                label="Name"
              />
              <FormHelperText key={`MO-customerGroupTypeName-FHT`}>
                {_.get(formik.touched, 'customerGroupType.name') &&
                  _.get(formik.errors, 'customerGroupType.name', '')}
              </FormHelperText>
            </FormControl>

            <div className={classes.checkboxDiv}>
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox
                    name="customerGroupType.companyRequired"
                    checked={_.get(
                      formik.values,
                      'customerGroupType.companyRequired'
                    )}
                    onChange={formik.handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="primary"
                  />
                }
                label="Company Required"
              />
            </div>
          </div>
        </>
      );

    case 'offerDetails':
      return (
        <>
          <React.Fragment>
            <div className={classes.offerDetailsDiv}>
              <div key="offerDetails-feeGroup-div" className={classes.titleMsg}>
                Fee Group
                <IconButton
                  key={`MO-feeGroup-icon-btn`}
                  aria-label="expand feeGroup"
                  size="small"
                  onClick={(_e) => {
                    if (collOpen === 'fg') {
                      formik.setErrors({});
                      _.set(formik.values, 'subCategory', '');
                      _.set(
                        formik.values,
                        'feeGroup',
                        initialOfferValues.feeGroup
                      );
                      setCollOpen('');
                    } else {
                      _.set(formik.values, 'subCategory', 'feeGroup');
                      //formik.values;
                      setCollOpen('fg');
                    }
                  }}
                  id={'feeGroup'}
                  style={{ float: 'right' }}
                  disabled={collOpen === 'fg' || collOpen === '' ? false : true}
                >
                  {collOpen === 'fg' ? (
                    <KeyboardArrowUpIcon key={`DM-arrow-up`} />
                  ) : (
                    <KeyboardArrowDownIcon key={`DM-arrow-down`} />
                  )}
                </IconButton>
              </div>
              <Collapse
                in={collOpen === 'fg'}
                timeout="auto"
                unmountOnExit
                style={{ margin: '10px 0px' }}
                key={`DM-collapse`}
              >
                <TextItems
                  classes={classes}
                  ODType="feeGroup"
                  countryCode={countryCode}
                  currencyId={currencyId}
                  formik={formik}
                />
              </Collapse>
            </div>
          </React.Fragment>
          <React.Fragment>
            <div className={classes.offerDetailsDiv}>
              <div
                key="offerDetails-priceProfile-div"
                className={classes.titleMsg}
              >
                Price Profile
                <IconButton
                  key={`MO-priceProfile-icon-btn`}
                  aria-label="expand row"
                  size="small"
                  onClick={(_e) => {
                    if (collOpen === 'pp') {
                      formik.setErrors({});
                      _.set(formik.values, 'subCategory', '');
                      _.set(
                        formik.values,
                        'priceProfile',
                        initialOfferValues.priceProfile
                      );
                      setCollOpen('');
                    } else {
                      //formik.values;
                      _.set(formik.values, 'subCategory', 'priceProfile');
                      setCollOpen('pp');
                    }
                  }}
                  id={'priceProfile'}
                  style={{ float: 'right' }}
                  disabled={collOpen === 'pp' || collOpen === '' ? false : true}
                >
                  {collOpen === 'pp' ? (
                    <KeyboardArrowUpIcon key={`DM-arrow-up`} />
                  ) : (
                    <KeyboardArrowDownIcon key={`DM-arrow-down`} />
                  )}
                </IconButton>
              </div>
              <Collapse
                in={collOpen === 'pp'}
                timeout="auto"
                unmountOnExit
                style={{ margin: '10px 0px' }}
                key={`DM-collapse`}
              >
                <TextItems
                  classes={classes}
                  ODType="priceProfile"
                  countryCode={countryCode}
                  currencyId={currencyId}
                  formik={formik}
                />
              </Collapse>
            </div>
          </React.Fragment>
          <React.Fragment>
            <div
              className={classes.offerDetailsDiv}
              style={{ marginBottom: 0 }}
            >
              <div
                key="offerDetails-tokenType-div"
                className={classes.titleMsg}
              >
                Token Type
                <IconButton
                  key={`MO-tokenTypeicon-btn`}
                  aria-label="expand row"
                  size="small"
                  onClick={(_e) => {
                    if (collOpen === 'tt') {
                      formik.setErrors({});
                      _.set(formik.values, 'subCategory', '');
                      _.set(
                        formik.values,
                        'tokenType',
                        initialOfferValues.tokenType
                      );
                      setCollOpen('');
                    } else {
                      //formik.values;
                      _.set(formik.values, 'subCategory', 'tokenType');
                      setCollOpen('tt');
                    }
                  }}
                  id={'tokenType'}
                  style={{ float: 'right' }}
                  disabled={collOpen === 'tt' || collOpen === '' ? false : true}
                >
                  {collOpen === 'tt' ? (
                    <KeyboardArrowUpIcon key={`DM-arrow-up`} />
                  ) : (
                    <KeyboardArrowDownIcon key={`DM-arrow-down`} />
                  )}
                </IconButton>
              </div>
              <Collapse
                in={collOpen === 'tt'}
                timeout="auto"
                unmountOnExit
                style={{ margin: '10px 0px' }}
                key={`DM-collapse`}
              >
                <TextItems
                  classes={classes}
                  ODType="tokenType"
                  countryCode={countryCode}
                  currencyId={currencyId}
                  formik={formik}
                />
              </Collapse>
            </div>
          </React.Fragment>
        </>
      );
    case 'cardDetails':
      return (
        <>
          <div key="cardType-grid-div" className={classes.titleMsg}>
            Card Type
          </div>
          <div style={{ display: 'flex' }}>
            <FormControl
              variant="outlined"
              className={classes.inputFormControl}
              error={
                _.get(formik.touched, 'cardType.name') &&
                Boolean(_.get(formik.errors, 'cardType.name'))
              }
              size="small"
            >
              <TextField
                type="text"
                key={`MO-cardTypeName-textfield`}
                name="cardType.name"
                variant="outlined"
                size="small"
                value={_.get(formik.values, 'cardType.name')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={classes.input}
                label="Name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      {...countryCode}
                      className={classes.inputAdornment}
                    />
                  ),
                }}
              />
              <FormHelperText key={`MO-customerGroupTypeName-FHT`}>
                {_.get(formik.touched, 'cardType.name') &&
                  _.get(formik.errors, 'cardType.name', '')}
              </FormHelperText>
            </FormControl>
            <FormControl
              variant="outlined"
              className={classes.inputFormControl}
              error={
                _.get(formik.touched, 'cardType.id') &&
                Boolean(_.get(formik.errors, 'cardType.id'))
              }
              size="small"
            >
              <TextField
                type="text"
                key={`MO-cardTypeId-textfield`}
                name="cardType.id"
                variant="outlined"
                size="small"
                value={_.get(formik.values, 'cardType.id')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={classes.input}
                label="Card Type ID"
              />
              <FormHelperText key={`MO-customerGroupTypeId-FHT`}>
                {_.get(formik.touched, 'cardType.id') &&
                  _.get(formik.errors, 'cardType.id', '')}
              </FormHelperText>
            </FormControl>
          </div>
        </>
      );
    default:
      return <></>;
  }
}

function TextItems(props: any) {
  const {
    classes,
    ODType,
    formik,
    countryCode,
    currencyId,
  }: {
    classes: any;
    ODType: 'feeGroup' | 'priceProfile' | 'tokenType';
    formik: any;
    countryCode: string;
    currencyId: string;
  } = props;
  return (
    <>
      <TextField
        type="hidden"
        key={`MO-customerGroupTypeName-textfield`}
        name="subCategory"
        variant="outlined"
        size="small"
        value={ODType}
        style={{ display: 'none' }}
      />
      <Grid key={`MO-${ODType}-grid`}>
        {offerDetailsTextItems[ODType].map((each: any, i: number) => (
          <FormControl
            variant="outlined"
            className={classes.inputFormControl}
            error={Boolean(
              _.get(formik.touched, each.key) && _.get(formik.errors, each.key)
            )}
            size="small"
            key={`MO-${ODType}-${each.key}-${i + 1}-formControl`}
          >
            <TextField
              aria-label={each.key}
              type="text"
              key={`MO-${ODType}-${each.key}-textfield`}
              name={each.key}
              variant="outlined"
              size="small"
              value={
                (each.key === 'feeGroup.name' ||
                  each.key === 'priceProfile.name' ||
                  each.key === 'tokenType.name') &&
                  _.get(formik.values, each.key).startsWith(`${countryCode} `)
                  ? _.get(formik.values, each.key).replace(
                    `${countryCode} `,
                    ''
                  )
                  : _.get(formik.values, each.key) || ''
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={classes.input}
              label={each.label}
              aria-invalid="false"
              InputProps={
                each.key === 'feeGroup.name' ||
                  each.key === 'priceProfile.name' ||
                  each.key === 'tokenType.name'
                  ? {
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        {countryCode}
                      </InputAdornment>
                    ),
                  }
                  : each.cur
                    ? {
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={classes.inputAdornment}
                        >
                          {currencyId}
                        </InputAdornment>
                      ),
                    }
                    : {}
              }
            />
            <FormHelperText key={`MO-${ODType}-${each.key}-${i + 1}-FHT`}>
              {_.get(formik.touched, each.key) &&
                _.get(formik.errors, each.key)}
            </FormHelperText>
          </FormControl>
        ))}
      </Grid>
    </>
  );
}

function formatValues(
  values: any,
  marketId: string,
  countryCode: string,
  dispatch: any
) {
  if (values.category === 'cardDetails') {
    values.cardType.countryCode = countryCode;
    values.cardType.isHybrid = values.cardType.isHybrid === 'true' ? true : false;
    dispatch(createCardTypeRequest(values.cardType));
  } else if (
    values.category === 'offerDetails' &&
    values.subCategory === 'feeGroup'
  ) {
    values.feeGroup.countryCode = countryCode;
    values.feeGroup.marketId = marketId;
    dispatch(createFeeGroupRequest(values.feeGroup));
  } else if (
    values.category === 'offerDetails' &&
    values.subCategory === 'priceProfile'
  ) {
    values.priceProfile.countryCode = countryCode;
    values.priceProfile.marketId = marketId;
    dispatch(createPriceProfileRequest(values.priceProfile));
  } else if (
    values.category === 'offerDetails' &&
    values.subCategory === 'tokenType'
  ) {
    values.tokenType.countryCode = countryCode;
    dispatch(createTokenTypeRequest(values.tokenType));
  }
}
