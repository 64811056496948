export const apiEndpoint = () => {
  console.log('Environment == ', process.env.NODE_ENV);
  switch (process.env.NODE_ENV) {
    case 'development':
      return 'https://app-shfaadminapi-qa.azurewebsites.net';
    case 'uat':
      return 'https://fleetapp-uat.shell.com/adminapi';
    case 'production':
      return 'https://fleetapp.shell.com/adminapi';
    default:
      return 'https://app-shfaadminapi-qa.azurewebsites.net';
  }
};
export const apiVersion = '1.0';
