import { Container, Grid, Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import UserDetailsSearch from './UserDetailsSearch';
import UserProfile from './UserManagement/UserProfile';
import UserOnboarding from './UserManagement/UserOnboarding';
import CustomerBusiness from './UserManagement/CustomerBusiness';
import OnfidoDetails from './UserManagement/OnfidoDetails';
import UserIpaas from './UserManagement/UserIpaas';
import UserPaymentCardReg from './UserManagement/UserPaymentCardReg';
import GfnAccount from './UserManagement/GfnAccount';
import UserFinanceDetails from './UserManagement/UserFinanceDetails';
import UserFuelCards from './UserManagement/UserFuelCards';
import CustomerAccountActions from './UserManagement/CustomerAccountActions';
import { connect } from 'react-redux';
import Spinner from './Spinner';
import Alert from '@material-ui/lab/Alert';
import StatusMessage from './UserManagement/StatusMessage';
import CustomerDetails from './UserManagement/CustomerDetails';
import GFNCustomerDetails from './UserManagement/GFNCustomerDetails';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    fontSize: '0.8rem !important',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '80vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& .MuiSnackbarContent-root': {
      backgroundColor: '#595959',
      lineHeight: 0,
    },
  },
  snackbar: {
    '& .MuiSnackbar-root': {
      position: 'sticky',
      paddingBottom: '1px',
    },
    '& .MuiSnackbarContent-root': {
      width: '100%',
      backgroundColor: '#595959',
      lineHeight: 0,
    },
  },
  fixedHeight: {
    height: 'auto',
  },
  spinnerParent: {
    padding: '10px 10px 10px 20px',
    height: 120,
  },
  paper: {
    padding: theme.spacing(1),
  },
  backButton: {
    margin: theme.spacing(1, 1, 2, 1),
    color: '#000000cf',
    backgroundColor: '#fbcf04',
    height: 40,
    fontFamily: 'Shell-Book',
    '&:hover': { background: '#fbcf04d1' },
  },
  titleMsg: {
    fontFamily: 'Shell-Medium',
    padding: '10px 0px',
    fontSize: '1rem',
    textAlign: 'left',
    width: '30%'
  },
  header: {
    display: 'flex',
    width: '100%',
  }
}));

function UserManagement(props: any) {
  const classes = useStyles();
  const {
    customerProfile,
    customerProfileLoading,
    customerProfileError,
    customerVerify,
    customerVerifyLoading,
    customerProfileSuccess,
    customerDelete,
    customerDeleteLoading,
    customerDeleteError,
    customerBlock,
    customerBlockLoading,
    customerBlockError,
    customerClose, customerCloseLoading, customerCloseError, displayCustomerDetails, searchById
  } = props;

  const [dataFetched, setDataFetched] = React.useState({
    dataFetchedVerify: false,
    dataFetchedDelete: false,
    dataFetchedBlock: false,
    dataFetchedClose: false,
  });

  const [state, setState] = React.useState({
    openVerifyStatus: false,
    openDeleteStatus: false,
    openBlockStatus: false,
    openCloseStatus: false,
    openCloseFormPopup:false,
  });

  const { dataFetchedVerify, dataFetchedDelete, dataFetchedBlock, dataFetchedClose } =
    dataFetched;
    const { openVerifyStatus, openDeleteStatus, openBlockStatus,  openCloseStatus, openCloseFormPopup } = state;

  //Customer Verify Status
  if (customerVerifyLoading || Object.keys(customerVerify).length === 0) {
    if (dataFetchedVerify) {
      setState({ ...state, openVerifyStatus: false });
      setDataFetched({ ...dataFetched, dataFetchedVerify: false });
    }
  } else if (
    !customerVerifyLoading &&
    !dataFetchedVerify &&
    Object.keys(customerVerify).length > 0
  ) {
    setState({ ...state, openVerifyStatus: true });
    setDataFetched({ ...dataFetched, dataFetchedVerify: true });
  }

  //Customer Delete Status
  if (customerDeleteLoading || Object.keys(customerDelete).length === 0) {
    if (dataFetchedDelete) {
      setState({ ...state, openDeleteStatus: false });
      setDataFetched({ ...dataFetched, dataFetchedDelete: false });
    }
  } else if (
    !customerDeleteLoading &&
    !dataFetchedDelete &&
    Object.keys(customerDelete).length > 0
  ) {
    setState({ ...state, openDeleteStatus: true });
    setDataFetched({ ...dataFetched, dataFetchedDelete: true });
  }

  //Customer Block Status
  if (customerBlockLoading || !customerBlock) {
    if (dataFetchedBlock) {
      setState({ ...state, openBlockStatus: false });
      setDataFetched({ ...dataFetched, dataFetchedBlock: false });
    }
  } else if (!customerBlockLoading && !dataFetchedBlock && customerBlock) {
    setState({ ...state, openBlockStatus: true });
    setDataFetched({ ...dataFetched, dataFetchedBlock: true });
  }

  //Customer Close Status
  if (customerCloseLoading || !customerClose) {
    if (dataFetchedClose) {
      setState({ ...state, openCloseStatus: false });
      setDataFetched({ ...dataFetched, dataFetchedClose: false });
    }
  } else if (!customerCloseLoading && !dataFetchedClose && customerClose) {
    setState({ ...state, openCloseStatus: true });
    setState({ ...state, openCloseFormPopup: false });
    setDataFetched({ ...dataFetched, dataFetchedClose: true });
  }

  useEffect(() => {
    if(customerClose){
     setState({ ...state, openCloseStatus: true });
    }
   },[customerClose]);

  const handleStatusClose = (status: string, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    if (status === 'delete') {
      setState({ ...state, openDeleteStatus: false });
    } else if (status === 'block') {
      setState({ ...state, openBlockStatus: false });
    } else if (status === 'verify') {
      setState({ ...state, openVerifyStatus: false });
    }else if (status === 'close') {
      setState({ ...state, openCloseStatus: false });
    }
  };

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <Container className={classes.container} maxWidth="lg">
          {!searchById && <UserDetailsSearch />}
          <div className={classes.snackbar}>
            {openDeleteStatus ? (
              <StatusMessage
                open={openDeleteStatus}
                handleClose={() => handleStatusClose('delete')}
                message={customerDelete.message}
              />
            ) : null}
            {openCloseStatus ? (
              <StatusMessage
                open={openCloseStatus}
                handleClose={() => handleStatusClose('close')}
                message={'The account closer request has been submitted. Please check after sometime.'}
              />
            ) : null}

            {openBlockStatus ? (
              <StatusMessage
                open={openBlockStatus}
                handleClose={() => handleStatusClose('block')}
                message={
                  customerBlock
                    ? 'The customer is blocked successfully.'
                    : customerBlockError
                      ? customerBlockError
                      : ''
                }
              />
            ) : null}
          </div>
          {openVerifyStatus ? (
            <StatusMessage
              open={openVerifyStatus}
              message={customerVerify.message}
              handleClose={() => handleStatusClose('verify')}
            ></StatusMessage>
          ) : null}
          {customerProfileLoading ? (
            <div className={classes.spinnerParent}>
              <Spinner custom />
            </div>
          ) : !customerProfileLoading &&
            Object.keys(customerProfile).length > 0 &&
            !customerDelete.success &&
            !customerBlock && !customerClose ? (
            <>
             {searchById && <div className={classes.header}>
              <div className={classes.titleMsg}>Customer Details</div>
              <div style={{width:"70%", display:'flex', justifyContent:"flex-end"}}>
              <Button
                type="button"
                variant="contained"
                size="large"
                className={classes.backButton}
                onClick={() => displayCustomerDetails(false)}
              >
                Go To Customer List
              </Button>
              </div>
              </div>}
              <Paper variant="outlined" className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <UserProfile></UserProfile>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <CustomerBusiness></CustomerBusiness>
                                </Grid>
                                <Grid item xs={12}>
                                  <UserIpaas></UserIpaas>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={6}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <OnfidoDetails></OnfidoDetails>
                                </Grid>
                                <Grid item xs={12}>
                                  <GfnAccount></GfnAccount>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={4}>
                        <Grid container spacing={2}>
                          {([1, 2, 3, 5, 100].indexOf(customerProfile.statusId) !==
                            -1 && customerProfile.subStatusId !== 28) ? (
                            <Grid item xs={12}>
                              <CustomerAccountActions></CustomerAccountActions>
                            </Grid>
                          ) : null}
                          <Grid item xs={12}>
                            <CustomerDetails></CustomerDetails>
                          </Grid>
                          <Grid item xs={12}>
                            <UserOnboarding
                              status={customerProfile.statusId}
                            ></UserOnboarding>
                          </Grid>
                          <Grid item xs={12}>
                            <GFNCustomerDetails />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {[1, 2, 5].indexOf(customerProfile.statusId) === -1 ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <UserPaymentCardReg></UserPaymentCardReg>
                        </Grid>
                        <Grid item xs={12}>
                          <UserFuelCards></UserFuelCards>
                        </Grid>
                        <Grid item xs={12}>
                          <UserFinanceDetails></UserFinanceDetails>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </Paper>
            </>
          ) : (
            <>
              {customerProfileError ? (
                <Alert severity="error">
                  {`Error loading customer - [${customerProfileError}]`}
                </Alert>
              ) : customerProfileSuccess === false ? (
                <Alert severity="info">No customer found</Alert>
              ) : null}
            </>
          )}
        </Container>
      </main>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    customerProfile: state.customerProfile.customerProfile,
    customerProfileLoading: state.customerProfile.pending,
    customerProfileError: state.customerProfile.error,
    customerVerify: state.customerVerify.customerVerify,
    customerVerifyLoading: state.customerVerify.pending,
    customerVerifyError: state.customerVerify.error,
    customerProfileSuccess: state.customerProfile.success,
    customerDelete: state.customerDelete.customerDelete,
    customerDeleteLoading: state.customerDelete.pending,
    customerDeleteError: state.customerDelete.error,
    customerBlock: state.customerBlock.customerBlock,
    customerBlockLoading: state.customerBlock.pending,
    customerBlockError: state.customerBlock.error,
    customerClose: state.accountClose.accountClose,
    customerCloseLoading: state.accountClose.pending,
    customerCloseError: state.accountClose.error,
  };
};

export default connect(mapStateToProps)(UserManagement);
