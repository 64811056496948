import * as Yup from 'yup';

export const manageOfferSchema = Yup.object().shape({
  subCategory: Yup.string().test(
    'subCategory',
    'Please expand any one option, fill all the details and submit',
    (value: any, ctx: any) => {
      if (ctx.parent.category === 'offerDetails' && (!value || value === '')) {
        return false;
      }
      return true;
    }
  ),

  customerGroupType: Yup.object().when('category', {
    is: 'aggregatorDetails',
    then: Yup.object({
      subCategory: Yup.string(),
      name: Yup.string().required('Please enter Customer Group Type Name'),
    }),
  }),

  feeGroup: Yup.object().when('category', {
    is: 'offerDetails',
    then: Yup.object().when('subCategory', {
      is: (subCategory: any) => subCategory && subCategory.includes('feeGroup'),
      then: Yup.object({
        name: Yup.string().required('Please enter Fee Group Name'),
        id: Yup.number()
          .test('FeeGroupId', '', validateOnlyNumeric)
          .required('Please enter ID'),
        setUpFee: Yup.number()
          .required('Please enter Setup Fee')
          .test('setUpFee', '', function (value: any) {
            if (!/^\d+(\.\d{0,2})?$/.test(value)) {
              return this.createError({
                message:
                  'Setup Fee should be only numeric and 2 or less digits after decimal or less',
              });
            }
            return true;
          }),
        monthlyFee: Yup.number()
          .required('Please enter Monthly Fee')
          .test('monthlyFee', '', function (value: any) {
            if (!/^\d+(\.\d{0,2})?$/.test(value)) {
              return this.createError({
                message:
                  'Monthly Fee should be only numeric and 2 or less digits after decimal or less',
              });
            }
            return true;
          }),
        cardMonthlyFee: Yup.number()
          .required('Please enter Card Monthly Fee')
          .test('cardMonthlyFee', '', function (value: any) {
            if (!/^\d+(\.\d{0,2})?$/.test(value)) {
              return this.createError({
                message:
                  'Card Monthly Fee should be only numeric and 2 or less digits after decimal or less',
              });
            }
            return true;
          }),
        transactionFee: Yup.number()
          .required('Please enter Transaction Fee')
          .test('transactionFee', '', function (value: any) {
            if (!/^\d+(\.\d{0,2})?$/.test(value)) {
              return this.createError({
                message:
                  'Transaction Fee should be only numeric and 2 or less digits after decimal or less',
              });
            }
            return true;
          }),
        annualFee: Yup.number()
          .required('Please enter Annual Fee')
          .test('annualFee', '', function (value: any) {
            if (!/^\d+(\.\d{0,2})?$/.test(value)) {
              return this.createError({
                message:
                  'Annual Fee should be only numeric and 2 or less digits after decimal or less',
              });
            }
            return true;
          }),
        cardReplacementFee: Yup.number()
          .required('Please enter Card Replacement Fee')
          .test('cardReplacementFee', '', function (value: any) {
            if (!/^\d+(\.\d{0,2})?$/.test(value)) {
              return this.createError({
                message:
                  'Card Replacement Fee should be only numeric and 2 or less digits after decimal or less',
              });
            }
            return true;
          }),
        oneOffFee: Yup.number()
          .required('Please enter One Off Fee')
          .test('oneOffFee', '', function (value: any) {
            if (!/^\d+(\.\d{0,2})?$/.test(value)) {
              return this.createError({
                message:
                  'One Off Fee should be only numeric and 2 or less digits after decimal or less',
              });
            }
            return true;
          }),
        ipaasCode: Yup.string().required('Please enter IPaas Code'),
      }),
    }),
  }),

  priceProfile: Yup.object().when('category', {
    is: 'offerDetails',
    then: Yup.object().when('subCategory', {
      is: (subCategory: any) =>
        subCategory && subCategory.includes('priceProfile'),
      then: Yup.object({
        name: Yup.string().required('Please enter Price Profile Name'),
        id: Yup.number()
          .test('priceProfileId', '', validateOnlyNumeric)
          .required('Please enter ID'),
        vPowerDiscount: Yup.number()
          .required('Please enter V Power Discount')
          .test('vPowerDiscount', '', function (value: any) {
            if (!/^\d+(\.\d{0,3})?$/.test(value)) {
              return this.createError({
                message:
                  'VPower Discount should be only numeric and 3 or less digits after decimal or less',
              });
            }
            return true;
          }),
        mainGrade: Yup.number()
          .required('Please enter Main Grade')
          .test('mainGrade', '', function (value: any) {
            if (!/^\d+(\.\d{0,3})?$/.test(value)) {
              return this.createError({
                message:
                  'Main Grade should be only numeric and 3 or less digits after decimal or less',
              });
            }
            return true;
          }),
        dieselAGO: Yup.number()
          .required('Please enter Diesel AGO')
          .test('dieselAGO', '', function (value: any) {
            if (!/^\d+(\.\d{0,3})?$/.test(value)) {
              return this.createError({
                message:
                  'Diesel AGO should be only numeric and 3 or less digits after decimal or less',
              });
            }
            return true;
          }),
        petrolMOGAS: Yup.number()
          .required('Please enter Petrol MOGAS')
          .test('petrolMOGAS', '', function (value: any) {
            if (!/^\d+(\.\d{0,3})?$/.test(value)) {
              return this.createError({
                message:
                  'Petrol MOGAS should be only numeric and 3 or less digits after decimal or less',
              });
            }
            return true;
          }),
        lubricants: Yup.number()
          .required('Please enter Lubricants')
          .test('lubricants', '', function (value: any) {
            if (!/^\d+(\.\d{0,2})?$/.test(value)) {
              return this.createError({
                message:
                  'Lubricants should be only numeric and 2 or less digits after decimal or less',
              });
            }
            return true;
          }),
        carWash: Yup.number()
          .required('Please enter Car Wash')
          .test('carWash', '', function (value: any) {
            if (!/^\d+(\.\d{0,2})?$/.test(value)) {
              return this.createError({
                message:
                  'Car Wash should be only numeric and 2 or less digits after decimal or less',
              });
            }
            return true;
          }),
        mainGradeTPN: Yup.number()
          .required('Please enter Main Grade TPN')
          .test('mainGradeTPN', '', function (value: any) {
            if (!/^\d+(\.\d{0,2})?$/.test(value)) {
              return this.createError({
                message:
                  'Main Grade TPN should be only numeric and 2 or less digits after decimal or less',
              });
            }
            return true;
          }),
        dieselAGOTPN: Yup.number()
          .required('Please enter Diesel AGO TPN')
          .test('dieselAGOTPN', '', function (value: any) {
            if (!/^\d+(\.\d{0,2})?$/.test(value)) {
              return this.createError({
                message:
                  'Diesel AGOTPN should be only numeric and 2 or less digits after decimal or less',
              });
            }
            return true;
          }),
        fuelSaveDiesel: Yup.number()
          .required('Please enter Fuel Save Diesel')
          .test('fuelSaveDiesel', '', function (value: any) {
            if (!/^\d+(\.\d{0,2})?$/.test(value)) {
              return this.createError({
                message:
                  'Fuel Save Diesel should be only numeric and 2 or less digits after decimal or less',
              });
            }
            return true;
          }),
        fuelSaveMOGAS: Yup.number()
          .required('Please enter Fuel Save MOGAS')
          .test('fuelSaveMOGAS', '', function (value: any) {
            if (!/^\d+(\.\d{0,2})?$/.test(value)) {
              return this.createError({
                message:
                  'Fuel Save MOGAS should be only numeric and 2 or less digits after decimal or less',
              });
            }
            return true;
          }),
        vPowerMOGAS: Yup.number()
          .required('Please enter V Power MOGAS')
          .test('vPowerMOGAS', '', function (value: any) {
            if (!/^\d+(\.\d{0,2})?$/.test(value)) {
              return this.createError({
                message:
                  'VPower MOGAS should be only numeric and 2 or less digits after decimal or less',
              });
            }
            return true;
          }),
        lpg: Yup.number()
          .required('Please enter LPG')
          .test('lpg', '', function (value: any) {
            if (!/^\d+(\.\d{0,2})?$/.test(value)) {
              return this.createError({
                message:
                  'LPG should be only numeric and 2 or less digits after decimal or less',
              });
            }
            return true;
          }),
        evShellNetwork: Yup.number()
          .required('Please enter EV Shell Network')
          .test('evShellNetwork', '', function (value: any) {
            if (!/^\d+(\.\d{0,2})?$/.test(value)) {
              return this.createError({
                message:
                  'EV Shell Network should be only numeric and 2 or less digits after decimal or less',
              });
            }
            return true;
          }),
        evOnRoam: Yup.number()
          .required('Please enter EV on Roam')
          .test('evOnRoam', '', function (value: any) {
            if (!/^\d+(\.\d{0,2})?$/.test(value)) {
              return this.createError({
                message:
                  'Ev on Roam should be only numeric and 2 or less digits after decimal or less',
              });
            }
            return true;
          }),
      }),
    }),
  }),

  tokenType: Yup.object().when('category', {
    is: 'offerDetails',
    then: Yup.object().when('subCategory', {
      is: (subCategory: any) =>
        subCategory && subCategory.includes('tokenType'),
      then: Yup.object({
        name: Yup.string().required('Please enter Token Type Name'),
        id: Yup.number()
          .test('tokenTypeId', '', validateOnlyNumeric)
          .required('Please enter Token Type ID'),
      }),
    }),
  }),

  cardType: Yup.object().when('category', {
    is: 'offerDetails',
    then: Yup.object().when('subCategory', {
      is: (subCategory: any) =>
        subCategory && subCategory.includes('cardType'),
      then: Yup.object({
        name: Yup.string().required('Please enter Card Type Name'),
        id: Yup.number()
          .test('cardTypeId', '', validateOnlyNumeric)
          .required('Please enter Card Type ID'),
      }),
    }),
  }),

  legalEntity: Yup.object().when('category', {
    is: 'offerDetails',
    then: Yup.object().when('subCategory', {
      is: (subCategory: any) =>
        subCategory && subCategory.includes('legalEntity'),
      then: Yup.object({
        name: Yup.string().required('Please enter Card Type Name'),
        id: Yup.number()
          .test('legalEntityId', '', validateOnlyNumeric)
          .required('Please enter Legal Entity ID'),
        ipaasCode: Yup.string().required('Please enter IPaas Code'),
      }),
    }),
  }),

  marketSegmantations: Yup.object().when('category', {
    is: 'aggregatorDetails',
    then: Yup.object({
      subCategory: Yup.string(),
      name: Yup.string().required('Please enter Marketing Segment'),
    }),
  }),

});

function validateOnlyNumeric(value: any, ctx: any) {
  if (!/^\d+$/.test(value)) {
    const errorMsg =
      ctx.path === 'feeGroup.id'
        ? 'Fee Group'
        : ctx.path === 'priceProfile.id'
          ? 'Price Profile '
          : ctx.path === 'tokenType.id'
            ? 'Token Type'
            : 'Card Type';
    return ctx.createError({
      message: `${errorMsg} ID should be only Numeric values`,
    });
  }
  return true;
}
