import React , {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { connect, useDispatch } from 'react-redux';
import Collapse from '@mui/material/Collapse';
import {
  deleteCustomerRequest,
  blockCustomerRequest,
  accountCloserRequest,
} from '../../store/user/actions';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import BlockIcon from '@mui/icons-material/Block';
import UserPermission from '../../store/UserPermission';
import ConfirmPopup from './ConfirmPopup';
import Dialog from '@material-ui/core/Dialog';
import { DialogTitle } from '@material-ui/core';
import DialogContent from '@mui/material/DialogContent';
import { Container, Grid, Paper, Card, FormControl, MenuItem, Select, InputLabel, FormHelperText, CircularProgress } from '@material-ui/core';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button'; 
import { useFormik } from 'formik';
import _ from 'lodash';
import TextField from '@mui/material/TextField';
import { CloseAccountValidation } from '../../schemas/closeAccountSchema';
import { ResponsiveContainer } from 'recharts';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& .MuiDialogContent-root': {
      padding: '20px 39px !important',
    },
    '& .MuiDialogTitle-root': {
      padding: '16px 82px !important',
    },
    '& .MuiDialogActions-root': {
      marginRight: '66px !important',
    },
  },
  card: {
    float: 'left',
    transition: '0.3s',
    boxShadow: '0 18px 4px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 40px -12.125px rgba(0,0,0,0.3)',
    },
    width: '100%',
  },
  cardAction: {
    width: '100%',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  box: {
    width: '100%',
    textAlign: 'left',
  },
  boxTitle: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px',
  },
  gfnRow: {
    marginTop: '6px',
  },
  collapse: {
    padding: '16px',
    backgroundColor: '#F7F7F7',
  },
  menuItems: {
    fontFamily: 'Shell-Book',
  },
  formControl: {
    '& .MuiSelect-select.Mui-disabled': { background: '#eee' },
    '& .MuiSelect-select:focus': {
      background: 'transparent',
    },
    margin: theme.spacing(1,1),
    minWidth: 250,
    width: 300,
  },
  dialogActions: {
    justifyContent: 'center',
  },
  circularProgress: {
    margin: theme.spacing(1, 2, 4, 2),
    color: '#fbcf04',
  },
  buttonIcon: {
    '&:hover': {
      background: '#fbcf04d1 !important',
    },
    background: '#fed700d1 !important',
    color:'#000000 !important',
    margin: theme.spacing(1),
    width: 110,
    height: 40,
    fontFamily: 'Shell-Book',
  },
}));



function CustomerAccountActions(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      closeAccountReasonOption: '',
      closeAccountReasonText: ''
    },
    validationSchema: CloseAccountValidation,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      let requestData = {
        userId: customerProfile.userId,
        reason:  `Close Account - ${values.closeAccountReasonOption} | Reason - ${values.closeAccountReasonText}` 
      };
      console.log(requestData);
      dispatch(accountCloserRequest(requestData));
      resetForm();
    },
  });

  const { customerProfile, customerDeleteLoading, customerBlockLoading ,customerCloseLoading,customerClose, customerCloseError} =    props;

  const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
  const [openBlockPopup, setOpenBlockPopup] = React.useState(false);
  const [openClosePopup, setOpenClosePopup] = React.useState(false);
  const [openCloseFormPopup, setOpenCloseFormPopup] = React.useState(false);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [loadingBlock, setLoadingBlock] = React.useState(false);
  const [loadingClose, setLoadingClose] = React.useState(false);
  


  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleClosePopup = () => {
    setOpenDeletePopup(false);
    setOpenBlockPopup(false);
    setOpenClosePopup(false);
    setOpenCloseFormPopup(false);
  };

  const handleDeleteConfirmClose = () => {
    setOpenDeletePopup(false);
    dispatch(
      deleteCustomerRequest({
        userId: customerProfile.userId,
      })
    );
  };

  const handleBlockConfirmClose = () => {
    setOpenBlockPopup(false);
    dispatch(
      blockCustomerRequest({
        gfnAccountId: customerProfile.gfnAccountId,
        freezeAccount: true,
      })
    );
  };

  const handleCloseConfirmPopUpClose = () => {
    setOpenClosePopup(false);
    setOpenCloseFormPopup(true);
  };
  useEffect(() => {
    setOpenCloseFormPopup(false);
  }, [customerClose]);

  const handleDeleteCustomer = () => {
    setOpenDeletePopup(true);
  };

  const handleBlockCustomer = () => {
    setOpenBlockPopup(true);
  };

  const handleCloseCustomer = () => {
    setOpenClosePopup(true);
  };
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Box className={classes.boxTitle} onClick={handleExpandClick}>
          <Typography
            component="div"
            sx={{
              textAlign: 'left',
              fontFamily: 'Shell-Book',
              fontWeight: expanded ? '600 !important' : '',
              color: expanded ? '#595959' : '',
              fontSize: '.9rem',
            }}
          >
            Account Actions
          </Typography>
          {!expanded ? (
            <ControlPointIcon
              color="action"
              fontSize="small"
            ></ControlPointIcon>
          ) : (
            <RemoveCircleOutlineIcon
              color="action"
              fontSize="small"
            ></RemoveCircleOutlineIcon>
          )}
        </Box>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Divider variant="middle" sx={{ my: 0, mx: 0 }} />
          <Box className={classes.collapse}>
            {openDeletePopup ? (
              <ConfirmPopup
                open={openDeletePopup}
                close={handleClosePopup}
                confirmClose={handleDeleteConfirmClose}
                title="Delete Customer Account"
                description="Are you sure you want to delete customer account?"
                btnText="Delete"
              ></ConfirmPopup>
            ) : null}

            {openBlockPopup ? (
              <ConfirmPopup
                open={openBlockPopup}
                close={handleClosePopup}
                confirmClose={handleBlockConfirmClose}
                title="Block Customer Account"
                description="Are you sure you want to block customer account?"
                btnText="Block"
              ></ConfirmPopup>
            ) : null}

            {openClosePopup ? (
              <ConfirmPopup
                open={openClosePopup}
                close={handleClosePopup}
                confirmClose={handleCloseConfirmPopUpClose}
                title="Close Customer Account"
                description="Are you sure you want to close customer account?"
                btnText="Close"
              ></ConfirmPopup>
            ) : null}
            <Stack direction="row" spacing={3}>
              {customerProfile.status &&
              (customerProfile.status.toLowerCase() === 'pending' ||
                customerProfile.status.toLowerCase() === 'new' ||
                customerProfile.status.toLowerCase() ===
                  'failedapplication') ? (
                <UserPermission component="Users" permissionFor="delete">
                  <LoadingButton
                    variant="outlined"
                    sx={{ fontSize: '0.7rem' }}
                    color="error"
                    size="small"
                    onClick={handleDeleteCustomer}
                    loading={
                      customerDeleteLoading ? !loadingDelete : loadingDelete
                    }
                    loadingPosition="end"
                    endIcon={<DeleteIcon />}
                  >
                    Delete
                  </LoadingButton>
                </UserPermission>
              ) : null}
              {customerProfile.status &&
              customerProfile.status.toLowerCase() === 'active' ? (
                <>
                <UserPermission component="Customer" permissionFor="update">
                    <LoadingButton
                      variant="outlined"
                      sx={{ fontSize: '0.7rem' }}
                      color="error"
                      size="small"
                      onClick={handleBlockCustomer}
                      loading={
                        customerBlockLoading ? !loadingBlock : loadingBlock
                      }
                      loadingPosition="end"
                      endIcon={<BlockIcon />}
                    >
                      Block Account
                    </LoadingButton>
                </UserPermission> 
                </>
              ) : null}
              {customerProfile.status &&
              (customerProfile.status.toLowerCase() === 'active' || customerProfile.status.toLowerCase() === 'readonlyaccess') 
              && !(customerProfile.subStatus.toLowerCase() === 'awaitingaccountclosure') ? (
              <>
                <UserPermission component="Customer" permissionFor="update">
              
                    <LoadingButton
                      variant="outlined"
                      sx={{ fontSize: '0.7rem' }}
                      color="error"
                      size="small"
                      onClick={handleCloseCustomer}
                      loading={
                        customerCloseLoading ? !loadingClose : loadingClose
                      }
                      loadingPosition="end"
                      endIcon={<CloseIcon />}
                    >
                      Close Account
                    </LoadingButton>

                </UserPermission>
              </>
               ) : null}
            </Stack>
            <Dialog   fullWidth maxWidth="sm" open={openCloseFormPopup}>
              <Container>
            <form onSubmit={formik.handleSubmit}>
            <DialogTitle>Close User account</DialogTitle>
            <ResponsiveContainer>
              <DialogContent>
                  <Grid container spacing={2} xs={12}>
                      <Grid alignItems="center" > 
                      <FormControl
                            key="DM-Select-CloseAccountReasonOption-formcontrol"
                            variant="outlined"
                            className={classes.formControl} size="small">
                            <InputLabel
                              key="Select-CloseAccountReasonOption-input-label"
                              id="Select-CloseAccountReasonOption-select-label"
                            >
                              Close Account Option
                            </InputLabel>
                            <Select
                              key="DM-CloseAccountReasonOption-select"
                              id="DM-CloseAccountReasonOption-select"
                              name="CloseAccountReasonOption"
                              labelId="CloseAccountReasonOption-select-label"
                              label="Close Account Reason"
                              autoWidth
                              value={formik.values.closeAccountReasonOption}
                              error={Boolean(
                                _.get(formik.touched, 'closeAccountReasonOption') &&
                                  _.get(formik.errors, 'closeAccountReasonOption')
                              )}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange("closeAccountReasonOption")}
                            >
                            <MenuItem
                                  key="FraudulentActivity"
                                  aria-selected={true}
                                  className = {classes.menuItems}
                                  value={"FraudulentActivity"}
                                >
                                  Fraudulent activity
                                </MenuItem>
                                <MenuItem
                                  key={"RequestFromBusiness"}
                                  aria-selected={true}
                                  className={classes.menuItems}
                                  value={"RequestFromBusiness"}
                                >
                                Request from business
                              </MenuItem>
                              <MenuItem
                                  key={"OtherReasons"}
                                  aria-selected={true}
                                  className={classes.menuItems}
                                  value={"OtherReasons"}
                                >
                                Other reasons
                              </MenuItem>
                            </Select>
                            <FormHelperText>
                            {formik.touched.closeAccountReasonOption && formik.errors.closeAccountReasonOption && 'Please select - Close Account Reason'}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid   alignItems="center">
                      <FormControl
                            key="DM-CloseAccountReason-formcontrol"
                            variant="outlined"
                            className={classes.formControl} size="small">
                          <TextField
                              error={Boolean(
                                _.get(formik.touched, 'closeAccountReasonText') &&
                                  _.get(formik.errors, 'closeAccountReasonText')
                              )}
                              helperText={formik.touched.closeAccountReasonText && formik.errors.closeAccountReasonText}
                              value={_.get(formik.values, 'closeAccountReasonText')}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              id="closeAccountReasonText"
                              name="closeAccountReasonText"
                              label="Reason for closing account"
                              placeholder="Reason for closing account"
                              variant="outlined"
                              maxRows={5}
                              multiline
                              inputProps={{ maxLength: 140 }}
                              size="small" />
                      </FormControl>
                      </Grid>
                  </Grid>
              </DialogContent>
            </ResponsiveContainer>
            <DialogActions>
             
            </DialogActions>
            <DialogActions className={classes.dialogActions} key={'dialogActions'} >
              {customerCloseLoading?
                       (<CircularProgress className={classes.circularProgress} />
                       ): (<>
                      
                       <Button
                        variant="contained"
                             size="small"
                             className={classes.buttonIcon}
                             title="Cancel"
                            type="submit">Submit</Button>
                            <Button
                            variant="contained"
                             size="small"
                             className={classes.buttonIcon}
                             title="Cancel"
                             onClick={() => { 
                              formik.resetForm()
                              setOpenCloseFormPopup(false)}}
                          >Cancel</Button>
                          </>
                       )}
              </DialogActions>
            </form>
            </Container>
                </Dialog>
          </Box>
        </Collapse>
      </Card>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    customerProfile: state.customerProfile.customerProfile,
    customerDeleteLoading: state.customerDelete.pending,
    customerBlockLoading: state.customerBlock.pending,

    customerClose: state.accountClose.accountClose,
    customerCloseLoading: state.accountClose.pending,
    customerCloseError: state.accountClose.error,
  };
};

export default connect(mapStateToProps)(CustomerAccountActions);
