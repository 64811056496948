import React from 'react';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Chip,
} from '@material-ui/core';
import _ from 'lodash';

function ViewOffers(props: any) {
    const { detailHeaders, row, classes, i, marketSegmantations } = props;
    return (
        <>
            <Table
                size="small"
                aria-label="purchases"
                style={{ margin: '10px 0px' }}
                key={`DM-${i + 1}-detail-table`}
            >
                <TableHead
                    key={`DM-${i + 1}-detailTable-head`}
                >
                    <TableRow
                        key={`DM-${i + 1}-detailTable-head-row`}
                    >
                        {detailHeaders.map(
                            (detailHeads: any, indx: number) => indx <= 8 && (
                                <TableCell
                                    key={`DM-${i + 1}-${detailHeads.label}-${indx + 1}`}
                                    className={classes.detailsTableHeader}
                                    align="center"
                                >
                                    {detailHeads.label}
                                </TableCell>
                            )
                        )}
                    </TableRow>
                </TableHead>
                <TableBody
                    key={`DM-${i + 1}-detailTable-body`}
                >
                    <TableRow
                        key={`DM-${i + 1}-detailTable-body-row`}
                    >
                        {detailHeaders.map(
                            (detailRow: any, inx: number) => inx <= 8 && (
                                <TableCell
                                    key={`DM-detailTable-${i + 1}-${detailRow.label}-cell`}
                                    className={classes.detailsTableBodyCell}
                                    align="center"
                                >
                                    {detailRow.key ===
                                        'offeringPackage.productGroups'
                                        ? _.get(
                                            row,
                                            detailRow.key.replace("offeringPackages", `offeringPackages[${i}]`),
                                            '-'
                                        ).map((pr: any) => (
                                            <Chip
                                                key={`chip-${pr.id}`}
                                                label={pr.name}
                                                className={classes.chips} />
                                        ))
                                        : _.get(
                                            row,
                                            detailRow.key.replace("offeringPackages", `offeringPackages[${i}]`),
                                            '-'
                                        ) || '-'}
                                </TableCell>
                            )
                        )}
                    </TableRow>
                </TableBody>
            </Table>
            <Table
                size="small"
                aria-label="purchases"
                style={{ margin: '10px 0px' }}
                key={`DM-${i + 1}-detail-table-2`}
            >
                <TableHead
                    key={`DM-${i + 1}-detailTable-head-2`}
                >
                    <TableRow
                        key={`DM-${i + 1}-detailTable-head-row-2`}
                    >
                        {detailHeaders.map(
                            (detailHeads: any, ix: number) => ix > 8 &&
                                ix <= 19 && (
                                    <TableCell
                                        key={`DM-${detailHeads.label}-${i + 1}-cell-${ix + 1}`}
                                        className={classes.detailsTableHeader}
                                        align="center"
                                    >
                                        {detailHeads.label}
                                    </TableCell>
                                )
                        )}
                    </TableRow>
                </TableHead>
                <TableBody
                    key={`DM-${i + 1}-detailTable-body-2`}
                >
                    <TableRow
                        key={`DM-${i + 1}-detailTable-body-row-2`}
                    >
                        {detailHeaders.map(
                            (detailRow: any, ixn: number) => ixn > 8 &&
                                ixn <= 19 && (
                                    <TableCell
                                        key={`DM-detailTable-${i + 1}-${detailRow.label}-cell-${ixn + 1}`}
                                        className={classes.detailsTableBodyCell}
                                        align="center"
                                    >
                                        {detailRow.key ===
                                            'offeringPackages.weeklyCardLimit' ||
                                            detailRow.key ===
                                            'offeringPackages.minWeeklyCardLimit' ||
                                            detailRow.key ===
                                            'offeringPackages.weeklyAccountLimit' ||
                                            detailRow.key ===
                                            'offeringPackages.dailyAccountLimit' ||
                                            detailRow.key === 'offeringPackages.dailyCardLimit' ||
                                            detailRow.key ===
                                            'offeringPackages.creditLimit' ||
                                            detailRow.key ===
                                            'offeringPackages.maxWeeklyCardLimit' ? (
                                            <>
                                                <span
                                                    key={`DM-${i + 1}-span-${ixn + 1}`}
                                                >
                                                    {_.get(
                                                        row,
                                                        detailRow.key.replace("offeringPackages", `offeringPackages[${i}]`),
                                                        '-'
                                                    )}
                                                </span>
                                                <span
                                                    key={`DM-${i + 1}-span-currency-${ixn + 1}`}
                                                    style={{ float: 'right' }}
                                                >
                                                    {_.get(
                                                        row,
                                                        'market.currencyId',
                                                        '-'
                                                    )}
                                                </span>
                                            </>
                                        ) : detailRow.key ===
                                            'offeringPackages.co2Offset' ? (
                                            _.get(row, detailRow.key.replace("offeringPackages", `offeringPackages[${i}]`)) ? (
                                                'True'
                                            ) : (
                                                'False'
                                            )
                                        ) : (
                                            _.get(
                                                row,
                                                detailRow.key.replace("offeringPackages", `offeringPackages[${i}]`),
                                                '-'
                                            ) || '-'
                                        )}
                                    </TableCell>
                                )
                        )}
                    </TableRow>
                </TableBody>
            </Table>
            <Table
                size="small"
                aria-label="purchases"
                style={{ margin: '10px 0px' }}
                key={`DM-${i + 1}-detail-table-3`}
            >
                <TableHead
                    key={`DM-${i + 1}-detailTable-head-3`}
                >
                    <TableRow
                        key={`DM-${i + 1}-detailTable-head-row-2`}
                    >
                        {detailHeaders.map(
                            (detailHeads: any, ix: number) => ix > 19 &&
                                ix <= 22 && (
                                    <TableCell
                                        key={`DM-${detailHeads.label}-${i + 1}-cell-${ix + 1}`}
                                        className={classes.detailsTableHeader}
                                        align="center"
                                    >
                                        {detailHeads.label}
                                    </TableCell>
                                )
                        )}
                    </TableRow>
                </TableHead>
                <TableBody
                    key={`DM-${i + 1}-detailTable-body-3`}
                >
                    <TableRow
                        key={`DM-${i + 1}-detailTable-body-row-3`}
                    >
                        {detailHeaders.map(
                            (detailRow: any, ixn: number) => ixn > 19 &&
                                ixn <= 22 && (
                                    <TableCell
                                        key={`DM-detailTable-${i + 1}-${detailRow.label}-cell-${ixn + 1}`}
                                        className={classes.detailsTableBodyCell}
                                        align="center"
                                    >
                                        {(detailRow.key ===
                                            'offeringPackages.monthlyCgpAccountLimitPercentage' ||
                                            detailRow.key ===
                                            'offeringPackages.weeklyCgpAccountLimitPercentage' ||
                                            detailRow.key ===
                                            'offeringPackages.dailyCgpAccountLimitPercentage') && (
                                                _.get(
                                                    row,
                                                    detailRow.key.replace("offeringPackages", `offeringPackages[${i}]`),
                                                    '-') || '-'
                                            )}
                                    </TableCell>
                                )
                        )}
                    </TableRow>
                </TableBody>
            </Table>
            <Table
                size="small"
                aria-label="purchases"
                style={{ margin: '10px 0px' }}
                key={`DM-${i + 1}-detail-table-4`}
            >
                <TableHead
                    key={`DM-${i + 1}-detailTable-head`}
                >
                    <TableRow
                        key={`DM-${i + 1}-detailTable-head-row`}
                    >
                        {detailHeaders.map(
                            (detailHeads: any, indx: number) => indx > 22 && indx < 25 && (
                                <TableCell
                                    key={`DM-${i + 1}-${detailHeads.label}-${indx + 1}`}
                                    className={classes.detailsTableHeader}
                                    align="center"
                                >
                                    {detailHeads.label}
                                </TableCell>
                            )
                        )}
                    </TableRow>
                </TableHead>
                <TableBody
                    key={`DM-${i + 1}-detailTable-body`}
                >
                    <TableRow
                        key={`DM-${i + 1}-detailTable-body-row`}
                    >
                        {detailHeaders.map(
                            (detailRow: any, inx: number) => inx > 22 && inx < 25 && (
                                <>
                                    <TableCell
                                        key={`DM-detailTable-${i + 1}-${detailRow.label}-cell`}
                                        className={classes.detailsTableBodyCell}
                                        align="center"
                                    >
                                        {detailRow.key ===
                                            'offeringPackages.productGroups'
                                            ? _.get(
                                                row,
                                                detailRow.key.replace("offeringPackages", `offeringPackages[${i}]`),
                                                '-'
                                            ).map((pr: any) => (
                                                <Chip
                                                    key={`chip-${pr.id}`}
                                                    label={pr.name}
                                                    className={classes.chips} />
                                            ))
                                            : _.get(
                                                row,
                                                detailRow.key,
                                                '-'
                                            ) || '-'}
                                        {detailRow.key ===
                                            'offeringPackages.marketsegmantation'
                                            ? marketSegmantations.map((pr: any) => (
                                                row.offeringPackages[0].marketsegmantation == pr.id && <Chip
                                                    key={`chip-${pr.id}`}
                                                    label={pr.name}
                                                    className={classes.chips} />
                                            ))
                                            : _.get(
                                                row,
                                                detailRow.key,
                                                '-'
                                            ) || '-'}
                                    </TableCell>
                                </>
                            )
                        )}
                    </TableRow>
                </TableBody>
            </Table>
            <Table
                size="small"
                aria-label="purchases"
                style={{ margin: '10px 0px' }}
                key={`DM-${i + 1}-detail-table-4`}
            >
                <TableHead
                    key={`DM-${i + 1}-detailTable-head-4`}
                >
                    <TableRow
                        key={`DM-${i + 1}-detailTable-head-row-4`}
                    >
                        {detailHeaders.map(
                            (detailHeads: any, ix: number) => ix >= 25 &&
                                ix <= 28 && (
                                    <TableCell
                                        key={`DM-${detailHeads.label}-${i + 1}-cell-${ix + 1}`}
                                        className={classes.detailsTableHeader}
                                        align="center"
                                    >
                                        {detailHeads.label}
                                    </TableCell>
                                )
                        )}
                    </TableRow>
                </TableHead>
                <TableBody
                    key={`DM-${i + 1}-detailTable-body-2`}
                >
                    <TableRow
                        key={`DM-${i + 1}-detailTable-body-row-2`}
                    >
                        {detailHeaders.map(
                            (detailRow: any, ixn: number) => ixn >= 25 &&
                                ixn <= 28 && (
                                    <TableCell
                                        key={`DM-detailTable-${i + 1}-${detailRow.label}-cell-${ixn + 1}`}
                                        className={classes.detailsTableBodyCell}
                                        align="center"
                                    >
                                        {detailRow.key ===
                                            'offeringPackages.planHeader' ||
                                            detailRow.key ===
                                            'offeringPackages.planFAQ' ||
                                            detailRow.key ===
                                            'offeringPackages.planOfferPoints' ? (
                                            <>
                                                <span
                                                    key={`DM-${i + 1}-span-${ixn + 1}`}
                                                >
                                                    {_.get(
                                                        row,
                                                        detailRow.key.replace("offeringPackages", `offeringPackages[${i}]`),
                                                        '-'
                                                    )}
                                                </span>
                                            </>
                                        ) :
                                            _.get(
                                                row,
                                                detailRow.key.replace("offeringPackages", `offeringPackages[${i}]`),
                                                '-'
                                            ) || '-'
                                        }
                                    </TableCell>
                                )
                        )}
                    </TableRow>
                </TableBody>
            </Table>
        </>)
}
export default ViewOffers;