import {
  FormControl,
  FormHelperText,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root, .MuiFormLabel-root, .MuiFormHelperText-root': {
      fontFamily: 'Shell-Book',
    },
    textAlign: 'left',
    margin: '10px 0px',
  },
  formControl: {
    '& .MuiSelect-select.Mui-disabled': { background: '#eee' },
    '& .MuiSelect-select:focus': {
      background: 'transparent',
    },
    margin: theme.spacing(1, 5),
    minWidth: 250,
    width: 325,
  },
  formControlButton: {
    margin: 0,
  },
  menuItems: {
    fontFamily: 'Shell-Book',
  },
  searchButton: {
    margin: theme.spacing(1, 2, 1, 2),
    color: '#000000cf',
    backgroundColor: '#fbcf04',
    height: 40,
    fontFamily: 'Shell-Book',
    '&:hover': { background: '#fbcf04d1' },
  },
  input: {
    margin: '0px',
    '& .MuiInputBase-root.Mui-disabled': { background: '#eee' },
  },
  customInput: {
    '& .MuiInputBase-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    paddingRight: 39,
    //zIndex: 99999,
  },
  inputFormControl: {
    margin: theme.spacing(1, 5),
    width: 325,
  },
  helperText: {
    marginLeft: 10,
  },
  titleMsg: {
    fontFamily: 'Shell-Medium',
    padding: '10px',
    fontSize: '1rem',
    textAlign: 'left',
  },
  divider: {
    margin: '20px 0px 10px 0px',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    margin: '0px 0px 0px 28px',
  },
  inputAdornment: {
    '& .MuiTypography-body1': { fontFamily: 'Shell-Book' },
  },
}));

export const currencyCode = {
  GB: 'GBP',
  NL: 'EUR',
  DE: 'EUR',
  CZ: 'CZK',
  SK: 'EUR',
  HK: 'HKD',
  SG: 'SGD',
  PH: 'PHP',
  PL: 'PLN',
  MY: 'MYR',
  IT: 'EUR',
};

export default function MasterTextItem(prop: any) {
  const {
    item,
    formik,
    offeringPackage,
    countryValue,
    isCurrency = false,
    isAddOffers,
    isEditOffer,
    isDisabled
  } = prop;
  const classes = useStyles();
  const formikError =
    _.get(formik.touched, item.key) && Boolean(_.get(formik.errors, item.key));
  const errorText =
    _.get(formik.touched, item.key) && _.get(formik.errors, item.key, '');

  const validateDecimal = (e: any) => {
    const t = e.target.value;
    e.target.value =
      t.indexOf('.') >= 0
        ? `${t.substr(0, t.indexOf('.'))}${t.substr(t.indexOf('.'), 3)}`
        : t;
  };
  return (
    <FormControl
      key={`DM-${item.key}-formcontrol`}
      variant="outlined"
      className={classes.inputFormControl}
      size="small"
      error={formikError}
    >
      <TextField
        disabled={isDisabled || isAddOffers || isEditOffer}
        type={item.type}
        key={`DM-${item.key}-textfield`}
        name={item.key}
        variant="outlined"
        size="small"
        className={classes.input}
        value={_.get(formik.values, item.key, '')}
        label={item.label}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formikError}
        inputProps={
          isCurrency && item.key !== 'maxNoCards'
            ? { step: 0.001, presicion: 2, onInput: validateDecimal }
            : {}
        }
        InputProps={
          item.key === 'dealName'
            ? {
                startAdornment: (
                  <InputAdornment
                    position="start"
                    {...countryValue}
                    className={classes.inputAdornment}
                  />
                ),
              }
            : isCurrency && item.key !== 'maxNoCards'
            ? {
                endAdornment: (
                  <InputAdornment
                    className={classes.inputAdornment}
                    position="end"
                    {
                      ...offeringPackage.currencyId
                        ? offeringPackage.currencyId
                        : _.get(currencyCode, countryValue, '')
                    }
                  />
                ),
              }
            : {}
        }
      />
      <FormHelperText key={`DM-${item.key}-FHT`} className={classes.helperText}>
        {errorText}
      </FormHelperText>
    </FormControl>
  );
}
