import {
  Button,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Modal,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { GROUP_TYPE } from '../constants/appConstants';
import { useFormik, FormikProps } from 'formik';
import { dealManagementValidation, editdealManagementValidation } from '../schemas/dealManagementSchema';
import MasterSelectItems from './MasterSelectItems';
import MasterTextItem from './MasterTextItems';
import CreateDealSummary from './CreateDealSummary';
import { createDealsRequest, updateDealsRequest, createOffersRequest, updateOffersRequest } from '../store/deals/actions';
import Spinner from './Spinner';
import Alert from '@material-ui/lab/Alert';
import { CreateDealsPayload } from '../store/deals/types';
import _ from 'lodash';
import ManageOffers from './ManageOffers';
import UserPermission from '../store/UserPermission';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root, .MuiFormLabel-root, .MuiFormHelperText-root': {
      fontFamily: 'Shell-Book',
    },
    textAlign: 'left',
    margin: '10px 0px',
  },
  formControl: {
    '& .MuiSelect-select.Mui-disabled': { background: '#eee' },
    '& .MuiSelect-select:focus': {
      background: 'transparent',
    },
    margin: theme.spacing(1, 5),
    minWidth: 250,
    width: 325,
  },
  formControlButton: {
    margin: 0,
  },
  formControlEditButton: {
    '&:hover': {
      background: '#fbcf04d1',
    },
    margin: 0,
    background: '#fbcf04',
    borderRadius: 5,
    width: 25,
    height: 25,
  },
  menuItems: {
    fontFamily: 'Shell-Book',
  },
  searchButton: {
    margin: theme.spacing(1, 2, 1, 2),
    color: '#000000cf',
    backgroundColor: '#fbcf04',
    height: 40,
    fontFamily: 'Shell-Book',
    '&:hover': { background: '#fbcf04d1' },
  },
  backButton: {
    margin: theme.spacing(1, 2, 1, 2),
    color: '#000000cf',
    backgroundColor: '#fbcf04',
    height: 40,
    fontFamily: 'Shell-Book',
    '&:hover': { background: '#fbcf04d1' },
  },
  input: {
    margin: '0px',
  },
  customInput: {
    '& .MuiInputBase-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    paddingRight: 39,
    //zIndex: 99999,
  },
  inputFormControl: {
    margin: theme.spacing(1, 5),
    width: 325,
  },
  helperText: {
    marginLeft: 10,
  },
  header: {
    fontFamily: 'Shell-Medium',
    padding: '10px',
    fontSize: '1.2rem',
    textAlign: 'center',
    float: 'left',
    width: '100%',
    background: '#fbd002ae',
    borderBottom: '1px solid #aaa',
  },
  titleMsg: {
    fontFamily: 'Shell-Medium',
    padding: '10px',
    fontSize: '1rem',
    textAlign: 'left',
    float: 'left',
  },
  divider: {
    margin: '20px 0px 10px 0px',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    margin: '0px 0px 0px 28px',
    '& span': {
      fontFamily: 'Shell-Book',
    },
  },
  editIconDiv: {
    float: 'right',
    padding: 5,
  },
  editIcon: {
    fontSize: 20,
  },
}));

export type EachItem = {
  key: string;
  label: string;
  type?: string;
};
export type SelectItems = {
  aggregatorDetails: EachItem[];
  offerDetails: EachItem[];
  accountDetails: EachItem[];
  cardDetails: EachItem[];
};

export const selectItems: SelectItems = {
  aggregatorDetails: [
    { key: 'customerGroupType', label: 'Customer Group Type' },
  ],
  offerDetails: [
    { key: 'feeGroup', label: 'Fee Group' },
    { key: 'priceProfile', label: 'Price Profile' },
    { key: 'productGroup', label: 'Product Group', type: 'multi' },
    { key: 'purchaseCategory', label: 'Purchase Category' },
    { key: 'tokenType', label: 'Token Type' },
    { key: 'legalEntity', label: 'Legal Entity' },
    { key: 'paymentTerm', label: 'Payment Term' },
    { key: 'billingFrequency', label: 'Billing Frequency' },
    { key: 'accountClerks', label: 'Account Clerk ID' },
    { key: 'marketSegmantations', label: 'Marketing Segment' },
  ],
  accountDetails: [
    { key: 'dailyAccountLimit', label: 'Daily Account Limit', type: 'text' },
    { key: 'weeklyAccountLimit', label: 'Weekly Account Limit', type: 'text' },
    { key: 'creditLimit', label: 'Account Credit Limit', type: 'text' },
    { key: 'monthlyCgpAccountLimitPercentage', label: 'FSGW Monthly Account Limit', type: 'text' },
    { key: 'weeklyCgpAccountLimitPercentage', label: 'FSGW Weekly Account Limit', type: 'text' },
    { key: 'dailyCgpAccountLimitPercentage', label: 'FSGW Daily Account Limit', type: 'text' }
  ],
  cardDetails: [{ key: 'cardType', label: 'Card Type' }],
};
export const textItems: SelectItems = {
  aggregatorDetails: [
    { key: 'dealName', label: 'Deal Name', type: 'text' },
    { key: 'title', label: 'Deal Description', type: 'text' },
  ],
  offerDetails: [
    { key: 'packageName', label: 'Offering Package Name', type: 'text' },
    { key: 'accountManagerId', label: 'Account Manager ID', type: 'text' },
  ],
  accountDetails: [
    { key: 'creditLimit', label: 'Account Credit Limit', type: 'text' },
    { key: 'weeklyAccountLimit', label: 'Weekly Account Limit', type: 'text' },
    { key: 'dailyAccountLimit', label: 'Daily Account Limit', type: 'text' },
    { key: 'monthlyCgpAccountLimitPercentage', label: 'FSGW Monthly Account Limit', type: 'text' },
    { key: 'weeklyCgpAccountLimitPercentage', label: 'FSGW Weekly Account Limit', type: 'text' },
    { key: 'dailyCgpAccountLimitPercentage', label: 'FSGW Daily Account Limit', type: 'text' }
  ],
  cardDetails: [
    { key: 'maxNoCards', label: 'Maximum Number of Cards', type: 'number' },
    { key: 'weeklyCardLimit', label: 'Default Weekly Card Limit', type: 'text' },
    { key: 'dailyCardLimit', label: 'Daily Card Limit', type: 'text' },
    { key: 'minWeeklyCardLimit', label: 'Min Weekly Card Limit', type: 'text' },
    { key: 'maxWeeklyCardLimit', label: 'Max Weekly Card Limit', type: 'text' }
  ],
};

const initialValues: CreateDealsPayload = {
  countryCode: '',
  marketId: '',
  customerGroupType: '',
  dealName: '',
  title: '',
  feeGroup: null,
  priceProfile: null,
  productGroup: [],
  purchaseCategory: null,
  tokenType: null,
  legalEntity: null,
  paymentTerm: null,
  billingFrequency: null,
  accountManagerId: '',
  accountClerks: '',
  cardType: null,
  maxNoCards: 0,
  creditLimit: 0,
  weeklyCardLimit: 0,
  minWeeklyCardLimit: 0,
  maxWeeklyCardLimit: 0,
  co2Offset: false,
  isMarketDefault: false,
  dailyAccountLimit: null,
  weeklyAccountLimit: null,
  monthlyCgpAccountLimitPercentage: null,
  weeklyCgpAccountLimitPercentage: null,
  dailyCgpAccountLimitPercentage: null,
  dailyCardLimit: null,
  packageName: '',
  marketSegmantations: '',
  marketingSegmentationId: '',
  id: '',
};

const OfferingPackageForm = (props: any) => {
  const classes = useStyles();
  const inputRef = useRef();
  const [open, setModalOpen] = useState(false);
  const [modalChild, setModalChild] = useState('');
  const [offerType, setOfferType] = useState('');
  const dispatch = useDispatch();
  const {
    offeringPackage,
    countryValue,
    dealCreated,
    dealCreatedError,
    dealUpdated,
    dealUpdatedError,
    offeringPackageError,
    setButtonDisable,
    isDealEdit,
    dealInitialValues,
    setEditDeal,
    setAddOffers,
    isAddOffers,
    isEditOffer,
    setEditOffer,
    offersCreated,
    offersCreatedError,
    offersUpdated,
    offersUpdatedError,
  } = props;

  const standardAggMap = [
    '66ec7d19-6042-4297-83e7-7a6311c2369e',
    '71414440-cf6e-49ac-9851-01fa1834d936',
    '8318b78b-f58c-4699-9207-bcf2e26fbd81',
    '56451f51-2de0-4dcd-a276-987d3036bc05',
  ];

  const formik: FormikProps<CreateDealsPayload> = useFormik<CreateDealsPayload>(
    {
      initialValues: (isDealEdit || isAddOffers || isEditOffer) ? dealInitialValues : initialValues,
      validationSchema: isDealEdit ? editdealManagementValidation : dealManagementValidation(offeringPackage),
      onSubmit: (values: CreateDealsPayload, { setSubmitting }) => {
        values.countryCode = countryValue;
        values.marketId = offeringPackage.marketId || '';
        setModalChild('dealSummary');
        setModalOpen(true);
        setSubmitting(false);
      },
    }
  );

  const handleFormSubmit = (values: CreateDealsPayload) => {
    setButtonDisable({ view: true, create: true });
    if (isEditOffer) {
      dispatch(updateOffersRequest(values));
    }
    if (isAddOffers) {
      dispatch(createOffersRequest(values));
    }
    if (isDealEdit) {
      dispatch(updateDealsRequest(values));
    } else if (!isEditOffer && !isAddOffers) {
      dispatch(createDealsRequest(values));
    }
    setModalChild('');
    setModalOpen(false);
    formik.resetForm();
  };
  function HandleAlert() {
    setButtonDisable({ view: false, create: false });
    let successMsg = '';
    let errorMsg = '';
    if (
      (!offersUpdated.pending && offersUpdated.response.success) ||
      (!offersCreated.pending && offersCreated.response.success)
    ) {
      successMsg = isEditOffer ? 'Offers updated Successfully. Click View button to see the updated details of the deals.' :

        offersCreated.response && offersCreated.response.success == true && <pre>
          Offers created Successfully.{'\n'}
          {JSON.stringify(offersCreated.response.data, undefined, 2)}
          {'\n'}Click View button to see more details of the deals.
        </pre>
    }
    else if (offersCreatedError || offersUpdatedError) {
      errorMsg = `Error creating Offers - [${offersCreatedError ||
        offersCreated.response.message ||
        offersUpdatedError ||
        offersUpdated.response.message
        }]`;
    }
    else if (
      (!dealUpdated.pending && dealUpdated.response.success) ||
      (!dealCreated.pending && dealCreated.response.success)
    ) {
      successMsg = isDealEdit ? 'Deals updated Successfully. Click View button to see the updated details of the deals.' : dealCreated.response && <pre>
        Deal created Successfully.{'\n'}
        {JSON.stringify(dealCreated.response.data, undefined, 2)}
        {'\n'}Click View button to see more details of the deals.
      </pre>
    }
    else if (dealCreatedError || dealUpdatedError) {
      errorMsg = `Error creating deals - [${dealCreatedError ||
        dealCreated.response.message ||
        dealUpdatedError ||
        dealUpdated.response.message
        }]`
    }
    else if (dealCreated?.response?.data == null || dealCreated?.response?.success == false) {
      errorMsg = `DealName already exists.`
    }
    return (
      <Alert key={successMsg !== '' ? 'DMS-handleAlert-success' : 'DMS-handleAlert-error'} severity={successMsg !== '' ? "success" : "error"}>
        {successMsg !== '' ? successMsg : errorMsg}
      </Alert>
    );
  }

  function handleBack() {
    setEditDeal(false);
    setAddOffers(false);
    setEditOffer(false);
  }

  if (countryValue === '') {
    return <></>;
  } else if (
    countryValue !== '' &&
    (dealCreated.pending || dealUpdated.pending || offersUpdated.pending || offersCreated.pending)
  ) {
    return (
      <Card
        className={classes.root}
        style={{ textAlign: 'center' }}
        variant="elevation"
      >
        <Spinner
          custom
          title={isEditOffer ? "Updating the Offer..." : isAddOffers ? 'Creating the Offer...' : isDealEdit ? 'Updating the Deal...' : 'Creating the Deal...'}
        />
      </Card>
    );
  }
  else if (
    !formik.isSubmitting &&
    countryValue !== '' &&
    (Object.keys(offersCreated.response).length > 0 ||
      offersCreatedError ||
      Object.keys(offersUpdated.response).length > 0 ||
      offersUpdatedError)
  ) {
    return (
      <Card className={classes.root} variant="elevation">
        <HandleAlert />
      </Card>
    );
  }
  else if (
    !formik.isSubmitting &&
    countryValue !== '' &&
    (Object.keys(dealCreated.response).length > 0 ||
      dealCreatedError ||
      Object.keys(dealUpdated.response).length > 0 ||
      dealUpdatedError)
  ) {
    return (
      <Card className={classes.root} variant="elevation">
        <HandleAlert />
      </Card>
    );
  } else if (
    countryValue !== '' &&
    Object.keys(offeringPackage).length > 0 &&
    !offeringPackageError
  ) {
    return (
      <>
        <Modal key={'dealSummaryModal'} open={open}>
          {modalChild === 'dealSummary' ? (
            <CreateDealSummary
              ref={inputRef}
              setModalOpen={setModalOpen}
              formSubmit={handleFormSubmit}
              offeringPackage={offeringPackage}
              values={formik.values}
              isDealEdit={isDealEdit}
              setSubmitting={formik.setSubmitting}
              standardAggMap={standardAggMap}
              isAddOffers={isAddOffers}
              isEditOffer={isEditOffer}
              setEditOffer={setEditOffer}
            />
          ) : (
            <ManageOffers
              offerType={offerType}
              ref={inputRef}
              setModalOpen={setModalOpen}
              offeringPackage={offeringPackage}
            />
          )}
        </Modal>

        <form onSubmit={formik.handleSubmit}>
          <Card className={classes.root} variant="elevation">
            <div className={classes.header}>
              {isAddOffers ? "Add Offers" : isEditOffer ? 'Edit Offers' : isDealEdit ? 'Edit Deal' : 'Create Deal'}
            </div>

            <div className={classes.titleMsg}>Aggregator Details</div>
            <Grid
              key="aggregatorDetails-grid-2"
              container
              justifyContent="flex-start"
            >
              {selectItems.aggregatorDetails.map(
                (eachAggrSelItem: EachItem) => {
                  if (
                    _.get(offeringPackage, eachAggrSelItem.key)?.length === 1
                  ) {
                    _.set(
                      formik.values,
                      eachAggrSelItem.key,
                      _.get(offeringPackage, eachAggrSelItem.key)[0].id
                    );
                  }
                  return (
                    <MasterSelectItems
                      key={eachAggrSelItem.key}
                      item={eachAggrSelItem}
                      formik={formik}
                      offeringPackage={offeringPackage}
                      countryValue={countryValue}
                      isDealEdit={isDealEdit}
                      isAddOffers={isAddOffers}
                      isEditOffer={isEditOffer}
                    />
                  );
                }
              )}
              {textItems.aggregatorDetails.map((eachAggrTextItem: EachItem) => (
                (standardAggMap.indexOf(formik.values?.customerGroupType) !== -1 && eachAggrTextItem.key === 'title') ? <></> : <MasterTextItem
                  key={eachAggrTextItem.key}
                  item={eachAggrTextItem}
                  formik={formik}
                  offeringPackage={offeringPackage}
                  countryValue={countryValue}
                  isCurrency={false}
                  isAddOffers={isAddOffers}
                  isEditOffer={isEditOffer}
                />
              ))}
              {standardAggMap.indexOf(formik.values?.customerGroupType) !==
                -1 ? (
                <Grid key="isMarketDefault-checkbox-grid">
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        key="isMarketDefault-checkbox"
                        name="isMarketDefault"
                        checked={formik.values.isMarketDefault}
                        onChange={formik.handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        color="primary"
                      />
                    }
                    label="Is Market Default"
                  />
                </Grid>
              ) : null}
            </Grid>
            {!isDealEdit && (
              <>
                <Divider className={classes.divider} />
                <div className={classes.titleMsg}>Offer Details</div>
                <Grid key="offerDetails-grid" container justifyContent="flex-start">
                  {textItems.offerDetails.map((eachOfferTextItem: EachItem) => (
                    <MasterTextItem
                      key={eachOfferTextItem.key}
                      item={eachOfferTextItem}
                      formik={formik}
                      offeringPackage={offeringPackage}
                      countryValue={countryValue}
                      isCurrency={false}
                      isDisabled={isEditOffer && eachOfferTextItem.key === 'packageName' && dealInitialValues.packageName !== ''}
                    />
                  ))}
                  {selectItems.offerDetails.map((eachOfferSelItem: EachItem) => {
                    if (_.get(offeringPackage, eachOfferSelItem.key).length === 1) {
                      _.set(
                        formik.values,
                        eachOfferSelItem.key,
                        _.get(offeringPackage, eachOfferSelItem.key)[0].id
                      );
                    }
                    return (
                      <MasterSelectItems
                        key={eachOfferSelItem.key}
                        item={eachOfferSelItem}
                        formik={formik}
                        offeringPackage={offeringPackage}
                        countryValue={countryValue}
                      />
                    );
                  })}
                  <Grid key="co2Offset-checkbox-grid">
                    <FormControlLabel
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          key="co2Offset-checkbox"
                          name="co2Offset"
                          checked={formik.values.co2Offset}
                          onChange={formik.handleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                          color="primary"
                        />
                      }
                      label="Co2 Offset"
                    />
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <div className={classes.titleMsg}>Account Details</div>
                <Grid key="accountDetails-grid" container justifyContent="flex-start">
                  {textItems.accountDetails.map((eachCardTextItem: EachItem) => {
                    const item = offeringPackage.billingFrequency.find((item: any) => item.id === formik.values.billingFrequency);
                    const groupType = item?.groupType || null;
                    return (<MasterTextItem
                      key={eachCardTextItem.key}
                      item={eachCardTextItem}
                      formik={formik}
                      offeringPackage={offeringPackage}
                      countryValue={countryValue}
                      isCurrency={true}
                      isDisabled={
                        eachCardTextItem.key === 'dailyAccountLimit' ||
                        eachCardTextItem.key === 'weeklyAccountLimit' ||
                        groupType === GROUP_TYPE.Weekly && eachCardTextItem.key === 'monthlyCgpAccountLimitPercentage' ||
                        groupType === GROUP_TYPE.BiWeekly && eachCardTextItem.key === 'monthlyCgpAccountLimitPercentage'
                      }
                    />);
                  })};
                </Grid>

                <Divider className={classes.divider} />
                <div className={classes.titleMsg}>Card Details</div>
                <Grid key="cardDetails-grid" container justifyContent="flex-start">
                  {selectItems.cardDetails.map((eachCardSelItem: EachItem) => {
                    if (_.get(offeringPackage, eachCardSelItem.key).length === 1) {
                      _.set(
                        formik.values,
                        eachCardSelItem.key,
                        _.get(offeringPackage, eachCardSelItem.key)[0].id
                      );
                    }
                    return (
                      <MasterSelectItems
                        key={eachCardSelItem.key}
                        item={eachCardSelItem}
                        formik={formik}
                        offeringPackage={offeringPackage}
                        countryValue={countryValue}
                      />
                    );
                  })}
                  {textItems.cardDetails.map((eachCardTextItem: EachItem) => (
                    <MasterTextItem
                      key={eachCardTextItem.key}
                      item={eachCardTextItem}
                      formik={formik}
                      offeringPackage={offeringPackage}
                      countryValue={countryValue}
                      isCurrency={true}
                    />
                  ))}
                </Grid>
              </>
            )}
          </Card>
          {(isDealEdit || isAddOffers || isEditOffer) && (
            <FormControl
              variant="outlined"
              className={classes.formControlButton}
              size="small"
            >
              <Button
                type="button"
                variant="contained"
                size="large"
                className={classes.backButton}
                onClick={() => handleBack()}
              >
                Back
              </Button>
            </FormControl>
          )}
          <FormControl
            variant="outlined"
            className={classes.formControlButton}
            size="small"
          >
            <UserPermission
              component="Deals"
              permissionFor={isDealEdit ? 'update' : 'create'}
            >
              <Button
                type="submit"
                variant="contained"
                size="large"
                className={classes.searchButton}
                disabled={
                  (isDealEdit || isEditOffer)
                    ? !formik.isValid || !formik.dirty
                    : !(formik.isValid && formik.dirty)
                }
              >
                Continue
              </Button>
            </UserPermission>
          </FormControl>
          {(isDealEdit || isEditOffer) && !formik.isValid ? (
            <div>
              <Alert severity="error">
                {Object.values(formik.errors).join(', ')}
              </Alert>
            </div>
          ) : isDealEdit && !formik.dirty ? (
            <div>
              <Alert severity="info">
                Please update atleast one field to enable Continue button.
              </Alert>
            </div>
          ) : (
            <></>
          )}
        </form>
      </>
    );
  } else {
    return (
      <Typography>
        <Alert severity="error">{`Error fetching Master Data for the Country - [${offeringPackageError}]`}</Alert>
      </Typography>
    );
  }
};
const mapStateToProps = (state: any) => {
  return {
    countries: state.countries.countries,
    countriesLoading: state.countries.pending,
    countriesError: state.countries.error,
    offeringPackage: state.offeringPackage.offeringPackage,
    offeringPackageResp: state.offeringPackage,
    offeringPackageLoading: state.offeringPackage.pending,
    OfferingPackageError: state.offeringPackage.error,
    dealCreated: state.dealCreated,
    dealCreatedError: state.dealCreated.error,
    dealUpdated: state.dealUpdated,
    dealUpdatedError: state.dealUpdated.error,
    offersCreated: state.offersCreated,
    offersCreatedError: state.offersCreated.error,
    offersUpdated: state.offersUpdated,
    offersUpdatedError: state.offersUpdated.error,
  };
};

export default connect(mapStateToProps)(OfferingPackageForm);
