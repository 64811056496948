import {
  Paper,
  Typography,
  IconButton,
  FormControl,
  TextField,
  Card,
} from '@material-ui/core';

import { SearchOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from '../Spinner';
import { ResponsiveContainer } from 'recharts';
import Alert from '@material-ui/lab/Alert';
import {
  fetchCardTypeRequest,
  resetCreateCardTypeRequest,
  resetUpdateCardTypeRequest,
} from '../../store/offeringPackages/actions';
import { FetchCardTypeRequestPayload } from '../../store/offeringPackages/types';
import CreateUpdateCardType from './CreateUpdateCardType';
import { DealMasterTable } from './DealMasterTable';
import { handleNumberValidation } from './helper';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  rootAlert: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  spinnerParent: {
    padding: '10px 10px 10px 20px',
    height: 120,
  },
  input: {
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 2,
    },
    margin: '0px',
  },
  inputFormControl: {
    margin: '8px 0px 0px 8px',
  },
  helperText: {
    marginLeft: 10,
  },
  chips: {
    margin: 1,
    background: '#aaaaaa50',
  },
  searchIcon: {
    '&:hover': { background: '#fbcf04d1' },
    '&.MuiIconButton-root.Mui-disabled': { background: '#ddddddcf' },
    background: '#fbcf04',
    padding: 6,
    borderRadius: 4,
    fontSize: 14,
  },
}));

const headers = [
  { label: 'Id', key: 'id' },
  { label: 'Card Type Name', key: 'name' },
  { label: 'IsHybrid?', key: 'isHybrid' },
  { label: 'Action', key: 'id' },
];

const cardTypeInitialValues: any = {
  category: '',
  subCategory: 'cardType',
  cardType: { countryCode: '', name: '', id: '', isHybrid: 'false' },
};

function ViewCardType(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');

  const {
    cardTypes,
    countryValue,
    cardTypesLoading,
    cardTypePage,
    cardTypesError,
    cardTypeCreated,
    cardTypeCreatedError,
    cardTypeUpdated,
    cardTypeUpdatedError,
    editDealMaster,
    setButtonDisable,
    setEditDealMaster,
    buttonDisable,
    setBtnClick,
    createUpdateStatus,
    setCreateUpdateStatus,
  } = props;

  if (cardTypesLoading) {
    return (
      <div className={classes.spinnerParent}>
        <Spinner custom />
      </div>
    );
  }

  const handleSearch = () => {
    dispatch(
      fetchCardTypeRequest({
        country: countryValue,
        cardTypeId: searchText,
      })
    );
  };

  const handleChangePage = (_event: any, page: any) => {
    const payload: FetchCardTypeRequestPayload = {
      country: countryValue,
      pageNumber: page + 1,
      cardTypeId: searchText,
    };
    dispatch(fetchCardTypeRequest(payload));
  };

  const handleEdit = (event: any) => {
    const card = cardTypes.filter((p: any) => p.id == event.currentTarget.id);
    cardTypeInitialValues.cardType = card[0];

    resetCardType();
    setButtonDisable({ ...buttonDisable, create: false });
    setCreateUpdateStatus({ ...createUpdateStatus, flag: false });
    setEditDealMaster(true);
  };

  function resetCardType() {
    if (
      Object.keys(cardTypeCreated.response).length > 0 ||
      cardTypeCreated.error ||
      Object.keys(cardTypeUpdated.response).length > 0 ||
      cardTypeUpdated.error
    ) {
      dispatch(resetCreateCardTypeRequest());
      dispatch(resetUpdateCardTypeRequest());
    }
  }

  function HandleAlert() {
    if (
      (!cardTypeUpdated.pending && cardTypeUpdated.response.success) ||
      (!cardTypeCreated.pending && cardTypeCreated.response.success)
    ) {
      return (
        <Alert key={'DMS-handleAlert-success'} severity="success">
          {createUpdateStatus.screen === 'update'
            ? ` Card Type updated Successfully.`
            : ` Card Type created Successfully.  ${JSON.stringify(
                cardTypeCreated.response.data,
                null,
                4
              )}.`}
        </Alert>
      );
    } else {
      return (
        <Alert key={'DMS-handleAlert-error'} severity="error">
          {`Error creating Card Type - [${
            cardTypeCreatedError ||
            cardTypeCreated.response.message ||
            cardTypeUpdatedError ||
            cardTypeUpdated.response.message
          }]`}
        </Alert>
      );
    }
  }

  return (
    <>
      {editDealMaster ? (
        <>
          <CreateUpdateCardType
            isDealMasterEdit={editDealMaster}
            cardTypeInitialValues={cardTypeInitialValues}
            countryValue={countryValue}
            setEditDealMaster={setEditDealMaster}
            setButtonDisable={setButtonDisable}
            setBtnClick={setBtnClick}
            buttonDisable={buttonDisable}
            createUpdateStatus={createUpdateStatus}
            setCreateUpdateStatus={setCreateUpdateStatus}
          />
        </>
      ) : (
        <>
          {createUpdateStatus.flag ? (
            <Card className={classes.root} variant="elevation">
              <HandleAlert />
            </Card>
          ) : (
            <></>
          )}
          <div className="search-result">
            <div className="search-result-text">Card Type Result</div>
            <div>
              <FormControl
                variant="outlined"
                className={classes.inputFormControl}
                error={false}
                size="small"
              >
                <TextField
                  name="searchPPText"
                  variant="outlined"
                  size="small"
                  className={classes.input}
                  label="Search Card Type Id"
                  value={searchText}
                  onChange={(e: any) => {
                    setSearchText(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') handleSearch();
                  }}
                  onKeyPress={handleNumberValidation}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={handleSearch}
                        className={classes.searchIcon}
                      >
                        <SearchOutlined />
                      </IconButton>
                    ),
                  }}
                />
              </FormControl>
            </div>
          </div>
          <ResponsiveContainer>
            {!cardTypesLoading && cardTypes.length > 0 ? (
              <Paper className={classes.root}>
                <DealMasterTable
                  headers={headers}
                  data={cardTypes}
                  page={cardTypePage}
                  handleEdit={handleEdit}
                  handleChangePage={handleChangePage}
                />
              </Paper>
            ) : (
              <Typography
                style={{ fontFamily: 'Shell-Book', textAlign: 'center' }}
              >
                {cardTypesError ? (
                  <Alert severity="error">
                    {`Error loading Card Types - [${cardTypesError}]`}
                  </Alert>
                ) : (
                  'No Card Type found'
                )}
              </Typography>
            )}
          </ResponsiveContainer>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    countries: state.countries.countries,
    cardTypes: state.cardTypes.cardTypes,
    cardTypesLoading: state.cardTypes.pending,
    cardTypesError: state.cardTypes.error,
    cardTypePage: state.cardTypes.page,
    cardTypeCreated: state.cardTypeCreated,
    cardTypeCreatedError: state.cardTypeCreated.error,
    cardTypeUpdated: state.cardTypeUpdated,
    cardTypeUpdatedError: state.cardTypeUpdated.error,
  };
};
export default connect(mapStateToProps)(ViewCardType);
