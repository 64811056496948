import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Grid } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import Collapse from '@mui/material/Collapse';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { fetchCustomerGfnH3accountRequest } from '../../store/user/actions';
import SkeletonComponent from './SkeletonComponent';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    card: {
        float: 'left',
        transition: '0.3s',
        boxShadow: '0 18px 4px -12px rgba(0,0,0,0.3)',
        '&:hover': {
            boxShadow: '0 16px 40px -12.125px rgba(0,0,0,0.3)',
        },
        width: '100%',
    },
    cardAction: {
        width: '100%',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    box: {
        width: '100%',
        textAlign: 'left',
    },
    boxTitle: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '16px',
    },
    gfnRow: {
        marginTop: '6px',
    },
    collapse: {
        padding: '16px',
        paddingTop: '8px',
        backgroundColor: '#F7F7F7',
    },
}));

interface gfn {
    id: string;
    value: any;
    label: string;
}

function GFNCustomerDetails(props: any) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [expanded, setExpanded] = React.useState(false);
    const {
        customerProfile,
        customerGfnH3account,
        customerGfnH3accountLoading,
        customerGfnH3accountError,
    } = props;
    const [isDataFetched, setIsDataFetched] = React.useState(false);
    const [gfnMap, setGfnMap] = React.useState([
        { id: '', value: '', label: '' },
    ]);

    if (customerGfnH3accountLoading) {
        if (isDataFetched) {
            setIsDataFetched(false);
        }
    } else if (!customerGfnH3accountLoading && !isDataFetched) {
        if (Object.keys(customerGfnH3account).length > 0) {
            loadMap();
        } else {
            setGfnMap([]);
        }
        setIsDataFetched(true);
    }

    function loadMap() {
        const gfnAccountDataMap: gfn[] = [
            {
                id: 'BillingFrequencyType',
                value: customerGfnH3account.billingFrequencyType ? customerGfnH3account.billingFrequencyType : '-',
                label: 'BillingFrequency',
            },
            {
                id: 'billingFrequencyTypeId',
                value: customerGfnH3account.billingFrequencyTypeId ? customerGfnH3account.billingFrequencyTypeId : '-',
                label: 'BillingFrequencyId',
            },
            {
                id: 'creditLimit',
                value: customerGfnH3account.creditLimit ? customerGfnH3account.creditLimit : '-',
                label: 'CreditLimit',
            },
            {
                id: 'accountStatus',
                value: customerGfnH3account.accountStatus ? customerGfnH3account.accountStatus : '-',
                label: 'AccountStatus',
            },
            {
                id: 'amountOverdue',
                value: customerGfnH3account.amountOverdue ? customerGfnH3account.amountOverdue : '-',
                label: 'AmountOverdue',
            },
            {
                id: 'totalCards',
                value: customerGfnH3account.totalCards ? customerGfnH3account.totalCards : '-',
                label: 'TotalCards',
            },
            {
                id: 'totalActiveCards',
                value: customerGfnH3account.totalActiveCards ? customerGfnH3account.totalActiveCards : '-',
                label: 'TotalActiveCards',
            },
            {
                id: 'totalExpiredCards',
                value: customerGfnH3account.totalExpiredCards ? customerGfnH3account.totalExpiredCards : '-',
                label: 'TotalExpiredCards',
            },
            {
                id: 'totalReplacedCards',
                value: customerGfnH3account.totalReplacedCards ? customerGfnH3account.totalReplacedCards : '-',
                label: 'TotalReplacedCards',
            },
            {
                id: 'vatRegNumber',
                value: customerGfnH3account.vatRegNumber ? customerGfnH3account.vatRegNumber : '-',
                label: 'VatRegNumber',
            },
            {
                id: 'vatRegNumber2',
                value: customerGfnH3account.vatRegNumber2 ? customerGfnH3account.vatRegNumber2 : '-',
                label: 'VatRegNumber2',
            },
        ];
        setGfnMap(gfnAccountDataMap);
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
        if (Object.keys(customerGfnH3account).length === 0) {
            dispatch(
                fetchCustomerGfnH3accountRequest({
                    customerId: customerProfile.customerId,
                })
            );
        }
    };

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                {localStorage.getItem('role') === 'superadmin' &&
                    <Box className={classes.boxTitle} onClick={handleExpandClick}>
                        <Typography
                            component="div"
                            sx={{
                                textAlign: 'left',
                                fontFamily: 'Shell-Book',
                                fontWeight: expanded ? '600 !important' : '',
                                color: expanded ? '#595959' : '',
                                fontSize: '.9rem',
                            }}
                        >
                            GFN Account Details
                        </Typography>
                        {!expanded ? (
                            <ControlPointIcon
                                color="action"
                                fontSize="small"
                            ></ControlPointIcon>
                        ) : (
                            <RemoveCircleOutlineIcon
                                color="action"
                                fontSize="small"
                            ></RemoveCircleOutlineIcon>
                        )}
                    </Box>}

                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Divider variant="middle" sx={{ my: 0, mx: 0 }} />
                    <Box className={classes.collapse}>
                        {isDataFetched ? (
                            <>
                                {gfnMap.length > 0 ? (
                                    gfnMap.map(({ label, value, id }) => {
                                        return (
                                            <Box className={classes.box} key={id}>
                                                <Grid container className={classes.gfnRow}>
                                                    <Grid item xs={6}>
                                                        {label}
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box sx={{ color: 'text.secondary' }}>{value}</Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        );
                                    })
                                ) : (
                                    <>
                                        {customerGfnH3accountError && (
                                            <Alert severity="info">
                                                No GFN account details found!
                                            </Alert>
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            <Box className={classes.box}>
                                <SkeletonComponent></SkeletonComponent>
                            </Box>
                        )}
                    </Box>
                </Collapse>
            </Card>
        </div >
    );
}

const mapStateToProps = (state: any) => {
    return {
        customerProfile: state.customerProfile.customerProfile,
        customerGfnH3account: state.customerGfnH3account.customerGfnH3account,
        customerGfnH3accountLoading: state.customerGfnH3account.pending,
        customerGfnH3accountError: state.customerGfnH3account.error,
    };
};
export default connect(mapStateToProps)(GFNCustomerDetails);