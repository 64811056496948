import {
  Button,
  Card,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { forwardRef, useState } from 'react';
import { ResponsiveContainer } from 'recharts';
import { currencyCode } from './MasterTextItems';
import { EachItem, selectItems, textItems } from './OfferingPackageForm';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '72ch',
    },
    '& .MuiInputBase-root, .MuiFormLabel-root': {
      fontFamily: 'Shell-Book',
      color: '#000000',
    },
    padding: 10,
  },
  input: {
    '&  .MuiFormLabel-root': {
      color: '#cb3931',
      fontWeight: 600,
    },
    '& .Mui-disabled': { background: '#f3f3f3' },
    '& .MuiFormLabel-root.Mui-disabled': { background: 'none' },
    margin: '0px',
  },
  productGroupInput: {
    '&  .MuiFormLabel-root': {
      color: '#cb3931',
      fontWeight: 600,
    },
    '& .Mui-disabled': { background: '#f3f3f3' },
    '& .MuiFormLabel-root.Mui-disabled': { background: 'none' },
    '& .MuiInputBase-root': {
      flexFlow: 'wrap',
    },
    margin: '0px',
  },
  titleMsg: {
    fontFamily: 'Shell-Medium',
    padding: '10px',
    fontSize: '1.2rem',
    textAlign: 'center',
  },
  divider: {
    margin: '20px 0px 10px 0px',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    margin: '0px 0px 0px 28px',
    '& span': {
      fontFamily: 'Shell-Book',
    },
  },
  searchButton: {
    margin: theme.spacing(1, 2, 1, 2),
    color: '#000000cf',
    backgroundColor: '#fbcf04',
    height: 40,
    fontFamily: 'Shell-Book',
    '&:hover': { background: '#fbcf04d1' },
  },
  editButton: {
    margin: theme.spacing(1, 2, 1, 2),
    color: '#000000cf',
    height: 40,
    width: 80,
    fontFamily: 'Shell-Book',
    '&:hover': { background: '#d4d4d4' },
  },
  chips: {
    margin: 1,
  },
  dialogActions: {
    justifyContent: 'center',
  },
  inputAdornment: {
    '& .MuiTypography-body1': { fontFamily: 'Shell-Book' },
  },
}));

const CreateDealSummary = forwardRef((props: any, ref: any) => {
  const classes = useStyles();
  const { setModalOpen, offeringPackage, formSubmit, values, isDealEdit, standardAggMap, isAddOffers, isEditOffer } =
    props;
  const [open, setDialogOpen] = useState(true);

  const handleButtonClick = () => {
    values.dealName = `${values.countryCode} ${values.dealName}`;
    if (!isDealEdit) {
      values.dailyCardLimit = values.dailyCardLimit == '' ? null : values.dailyCardLimit;
      values.dailyAccountLimit = values.dailyAccountLimit == '' ? null : values.dailyAccountLimit;
      values.weeklyCgpAccountLimitPercentage = values.weeklyCgpAccountLimitPercentage == '' ? null : values.weeklyCgpAccountLimitPercentage;
      values.weeklyAccountLimit = values.weeklyAccountLimit == '' ? null : values.weeklyAccountLimit;
      values.dailyCgpAccountLimitPercentage = values.dailyCgpAccountLimitPercentage == '' ? null : values.dailyCgpAccountLimitPercentage;
      values.monthlyCgpAccountLimitPercentage = values.monthlyCgpAccountLimitPercentage == '' ? null : values.monthlyCgpAccountLimitPercentage;
      values.packageName = values.packageName == '' ? null : values.packageName;
      values.marketingSegmentationId = values.marketSegmantations == '' ? null : values.marketSegmantations;
    }
    formSubmit(values);
  };

  return (
    <Dialog key={'dialog'} ref={ref} open={open} fullWidth maxWidth="lg">
      <DialogTitle key={'dialogTitle'}>
        {isAddOffers ? 'Add Offers Summary' : isEditOffer ? 'Create Offers Summary' : isDealEdit ? 'Edit Deals Summary' : 'Create Deals Summary'}
      </DialogTitle>
      <DialogContent key={'dialogContent'}>
        <ResponsiveContainer key="responsiveContainer">
          <Card
            key="aggregatorDetails-grid-card"
            className={classes.root}
            variant="elevation"
          >
            <div key="aggregatorDetails-grid-div" className={classes.titleMsg}>
              Aggregator Details
            </div>
            <Grid
              key="aggregatorDetails-grid"
              container
              justifyContent="flex-start"
            >
              {selectItems.aggregatorDetails.map(
                (item: EachItem, ix: number) => (
                  <TextField
                    type={item.type}
                    key={`DMS-${item.key}-textfield-${ix}`}
                    name={item.key}
                    variant="outlined"
                    size="small"
                    className={classes.input}
                    label={item.label}
                    disabled
                    value={
                      _.get(offeringPackage, item.key).find(
                        (each: any) => each.id == _.get(values, item.key)
                      ).name
                    }
                  />
                )
              )}
              {textItems.aggregatorDetails.map((item: EachItem, ix: number) => (
                <TextField
                  type={item.type}
                  key={`DMS-${item.key}-textfield-${ix}`}
                  name={item.key}
                  variant="outlined"
                  size="small"
                  className={classes.input}
                  label={item.label}
                  disabled
                  value={
                    item.key === 'dealName'
                      ? `${values.countryCode} ${values.dealName}`
                      : _.get(values, item.key)
                  }
                />
              ))}
              {standardAggMap.indexOf(values.customerGroupType) !== -1 ? (
                <FormControlLabel
                  key={'DMS-checkbox-FormControl'}
                  className={classes.checkbox}
                  control={
                    <Checkbox
                      key={'DMS-isMarketDefault-checkbox'}
                      name="isMarketDefault"
                      checked={values.isMarketDefault}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                      disabled
                    />
                  }
                  label="Is Market Default"
                />
              ) : null}
            </Grid>
            {!isDealEdit && (
              <><Divider key={'divider-1'} className={classes.divider} />
                <div key={'offerDetails-div'} className={classes.titleMsg}>
                  Offer Details
                </div>
                <Grid key="offerDetails-grid" container justifyContent="flex-start">
                  {textItems.offerDetails.map((item: EachItem, ix: number) => (
                    <TextField
                      type={item.type}
                      key={`DMS-${item.key}-textfield-${ix}`}
                      name={item.key}
                      variant="outlined"
                      size="small"
                      className={classes.input}
                      label={item.label}
                      disabled
                      value={_.get(values, item.key)}
                    />
                  ))}
                  {selectItems.offerDetails.map((item: EachItem, ix: number) => (
                    <TextField
                      type={item.type}
                      key={`DMS-${item.key}-textfield-${ix}`}
                      name={item.key}
                      variant="outlined"
                      size="small"
                      className={
                        item.key === 'productGroup'
                          ? classes.productGroupInput
                          : classes.input
                      }
                      label={item.label}
                      multiline
                      InputProps={
                        item.key === 'productGroup'
                          ? {
                            startAdornment: _.get(values, item.key, []).map(
                              (each: any) =>
                                _.get(offeringPackage, item.key, [])
                                  .filter((itm: any) => itm.id === each)
                                  .map((lab: any) => (
                                    <Chip
                                      key={`chip-${lab.id}`}
                                      label={lab.name}
                                      className={classes.chips}
                                    />
                                  ))
                            ),
                          }
                          : {}
                      }
                      value={
                        item.key === 'productGroup'
                          ? ''
                          : _.get(offeringPackage, item.key).find(
                            (each: any) => each.id === _.get(values, item.key)
                          )?.name
                      }
                      disabled
                    />
                  ))}
                  {/* {textItems.offerDetails.map((item: EachItem, ix: number) => (
                <TextField
                  type={item.type}
                  key={`DMS-${item.key}-textfield-${ix}`}
                  name={item.key}
                  variant="outlined"
                  size="small"
                  className={classes.input}
                  label={item.label}
                  disabled
                  value={_.get(values, item.key)}
                />
              ))} */}

           

                  <Grid key={'DMS-checkbox-grid'}>
                    <FormControlLabel
                      key={'DMS-checkbox-FormControl'}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          key={'DMS-co2Offset-checkbox'}
                          name="co2Offset"
                          checked={values.co2Offset}
                          inputProps={{ 'aria-label': 'controlled' }}
                          color="primary"
                          disabled
                        />
                      }
                      label="Co2 Offset"
                    />
                  </Grid>
                </Grid>
                <Divider key="divider-2" className={classes.divider} />
                <div key={'accountDetails-div'} className={classes.titleMsg}>
                  Account Details
                </div>
                <Grid key="accountDetails-grid" container justifyContent="flex-start">
                  {textItems.accountDetails.map((item: EachItem, ix: number) => (
                    <TextField
                      type={item.type}
                      key={`DMS-${item.key}-textfield-${ix}`}
                      name={item.key}
                      variant="outlined"
                      size="small"
                      className={classes.input}
                      label={item.label}
                      disabled
                      value={_.get(values, item.key)}
                    />
                  ))}
                </Grid>

                <Divider key={'divider-3'} className={classes.divider} />
                <div key="cardDetails-div" className={classes.titleMsg}>
                  Card Details
                </div>
                <Grid key="cardDetails-grid" container justifyContent="flex-start">
                  {selectItems.cardDetails.map((item: EachItem, ix: number) => (
                    <TextField
                      type={item.type}
                      key={`DM-${item.key}-textfield-${ix}`}
                      name={item.key}
                      variant="outlined"
                      size="small"
                      className={classes.input}
                      label={item.label}
                      disabled
                      value={
                        _.get(offeringPackage, item.key, []).find(
                          (each: any) => each.id === _.get(values, item.key)
                        ).name
                      }
                    />
                  ))}
                  {textItems.cardDetails.map((item: EachItem, ix: number) => (
                    <TextField
                      type={item.type}
                      key={`DM-${item.key}-textfield-${ix}`}
                      name={item.key}
                      variant="outlined"
                      size="small"
                      className={classes.input}
                      label={item.label}
                      disabled
                      value={_.get(values, item.key)}
                      InputProps={
                        item.key !== 'maxNoCards'
                          ? {
                            endAdornment: (
                              <InputAdornment
                                className={classes.inputAdornment}
                                position="end"
                                {
                                ...offeringPackage.currencyId
                                  ? offeringPackage.currencyId
                                  : _.get(
                                    currencyCode,
                                    _.get(offeringPackage, 'country'),
                                    ''
                                  )
                                }
                              />
                            ),
                          }
                          : {}
                      }
                    />
                  ))}
                </Grid>
                {/* <Divider key={'divider-2'} className={classes.divider} />
                <div key={'offerDetails-div'} className={classes.titleMsg}>
                  Account Details
                </div>
                <Grid key="offerDetails-grid" container justifyContent="flex-start">
                  {textItems.accountDetails.map((item: EachItem, ix: number) => (
                    <TextField
                      type={item.type}
                      key={`DMS-${item.key}-textfield-${ix}`}
                      name={item.key}
                      variant="outlined"
                      size="small"
                      className={classes.input}
                      label={item.label}
                      disabled
                      value={_.get(values, item.key)}
                    />
                  ))}
                </Grid> */}
              </>)}
          </Card>
        </ResponsiveContainer>
      </DialogContent>
      <DialogActions className={classes.dialogActions} key={'dialogActions'}>
        <Button
          key={'DMS-edit-button'}
          color="default"
          onClick={() => {
            setDialogOpen(false);
            setModalOpen(false);
          }}
          variant="contained"
          className={classes.editButton}
        >
          Edit
        </Button>
        {
          <Button
            key={'DMS-submit-button'}
            type="button"
            onClick={handleButtonClick}
            className={classes.searchButton}
            variant="contained"
          >
            Submit
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
});
export default CreateDealSummary;
