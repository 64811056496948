import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    IconButton,
    Tooltip
  } from '@material-ui/core';
  
  import { Edit } from '@material-ui/icons';
  import { makeStyles } from '@material-ui/core/styles';
  import _ from 'lodash';
  import React from 'react';

  const useStyles = makeStyles((theme) => ({
    control: {
      padding: theme.spacing(2),
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    tableHeader: {
      background: '#fed700d1',
      fontFamily: 'Shell-Book',
      fontWeight: 900,
      border: '1px solid #aaaaaa',
      color: '#484848',
    },
    tableBodyCell: {
      border: '1px solid #dddddd',
      fontFamily: 'Shell-Book',
    },
    detailsTableHeader: {
      background: '#fed70075',
      fontFamily: 'Shell-Medium',
      fontSize: 12,
      border: '1px solid #aaaaaa',
      padding: 5,
    },
    detailsTableBodyCell: {
      border: '1px solid #aaaaaa',
      fontFamily: 'Shell-Book',
      fontSize: 12,
      padding: 5,
    },
    rootAlert: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    actionBtn: {
      '&:hover': { background: '#fbcf04d1' },
      cursor: 'pointer',
      background: '#fbcf04',
      padding: 5,
      margin: 2,
      width: 25,
      height: 25,
      borderRadius: 4,
      fontSize: 14,
      boxShadow:
        '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    },
    chips: {
      margin: 1,
      background: '#aaaaaa50',
    },
    selected: { '&> td': { fontWeight: 900, background: '#f0f0f0' } },
    tableRow: {
      cursor: 'pointer',
      '&:hover': {
        background: '#f0f0f0d1',
      },
    },
  }));

export const DealMasterTable = (props: any) => {

    const classes = useStyles();
    const {
        headers,
        data,
        page,
        handleEdit,
        handleChangePage
    } = props;

    return (
        <>
            <TableContainer className={classes.container}>
                <Table aria-label="collapsible table" key="table">
                  <TableHead
                    className={classes.tableHeader}
                    key={'table-head'}
                  >
                    <TableRow key="DM-table-head-row">
                      {headers.map((header: any) => {
                        return (
                          <TableCell
                            key={`DM-${header.label}`}
                            className={classes.tableHeader}
                            align="center"
                          >
                            {header.label}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody key={'DM-table-body-main'}>
                    {data.map((row: any, i: any) => (
                      <React.Fragment key={`DM-react-fragment-${i + 1}`}>
                        <TableRow
                          key={`DM-table-${i + 1}-row-main`}
                          className={classes.tableRow}
                          classes={{
                            selected: classes.selected,
                          }}
                        >
                          {headers.map((header: any) => (
                            <TableCell
                              key={`DM-table-${i + 1}-${header.label}-cell`}
                              className={classes.tableBodyCell}
                              align="center"
                              style={
                                header.key === 'customer.maskedEmail'
                                  ? { wordBreak: 'break-all' }
                                  : {}
                              }
                            >
                              {(header.key === 'name' || header.key === 'isHybrid') ? (
                                <>
                                  <span>{`${_.get(row, header.key)}`}</span>{' '}
                                </>
                              ) : header.key === 'id' && header.label === 'Action'? (
                                <>
                                  <Tooltip title="Edit" arrow>
                                    <IconButton
                                      key={`DM-icon-${header.key}-${
                                        i + 1
                                      }-edit`}
                                      size="small"
                                      onClick={handleEdit}
                                      className={classes.actionBtn}
                                      id={_.get(row, header.key)}
                                    >
                                      <Edit style={{ fontSize: 20 }} />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )  : (
                                _.get(row, header.key, '-') || '-'
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                        <TableRow
                          key={`DM-${i + 1}-collapsble-row`}
                          style={{ border: '1px solid #dddddd' }}
                        >
                          <TableCell
                            key={`DM-${i + 1}-collapsble-cell`}
                            style={{
                              paddingBottom: 0,
                              paddingTop: 0,
                              background: '#efefefef',
                            }}
                            colSpan={8}
                          >
                            
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {page && Object.keys(page).length > 0 ? (
                <TablePagination
                  rowsPerPageOptions={[page.pageSize]}
                  component="div"
                  count={
                    page.totalNoOfRecords
                      ? page.totalNoOfRecords
                      : page.totalPages * page.pageSize
                  }
                  rowsPerPage={page.pageSize}
                  page={page.pageNumber - 1}
                  backIconButtonProps={{
                    color: 'secondary',
                  }}
                  nextIconButtonProps={{ color: 'secondary' }}
                  onPageChange={handleChangePage}
                />
              ) : (
                <></>
              )}
        </>
    )
};